var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body d5-body"},[_c('div',{staticClass:"full-ad bg1",class:{'bg-loading':_vm.beforeBg1},attrs:{"id":"d5_bg1"}},[_c('div',{staticClass:"ad-body ad-body-2"},[_c('div',{staticClass:"ad-product"},[_vm._v("素士D5云感刷")]),_vm._m(0),_c('div',{staticClass:"ad-button",on:{"click":_vm.playVid}},[_vm._v("观看视频 >")]),_c('div',{staticClass:"ad-label"},[_vm._v("1")])]),_vm._m(1),_c('div',{staticClass:"ad-cert-text"},[_vm._v("敏牙专研")]),_c('div',{staticClass:"ad-video"},[_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('video',{staticClass:"video",attrs:{"id":"vid001","controls":"","muted":"muted","preload":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://file.soocas.com/sg9_resource/soocas-site/detail-d5/tmall_d5_vid_20231207.mp4","type":"video/mp4"}})]),_c('div',{staticClass:"ad-close-video",on:{"click":_vm.exitFullScreen}})])])]),_vm._m(2),_c('div',{staticClass:"full-ad full-ad-1 bg2",class:{'bg-loading':_vm.beforeBg2},attrs:{"id":"d5_bg2"}},[_vm._m(3)]),_c('div',{staticClass:"full-ad full-ad-2 bg3",class:{'bg-loading':_vm.beforeBg3},attrs:{"id":"d5_bg3"}},[_vm._m(4)]),_c('div',{staticClass:"full-ad full-ad-3 bg4",class:{'bg-loading':_vm.beforeBg4},attrs:{"id":"d5_bg4"}},[_vm._m(5)]),_c('div',{staticClass:"full-ad full-ad-2 full-ad-4 bg5",class:{'bg-loading':_vm.beforeBg5},attrs:{"id":"d5_bg5"}},[_vm._m(6)]),_c('div',{staticClass:"full-ad full-ad-3 full-ad-5 bg6",class:{'bg-loading':_vm.beforeBg6},attrs:{"id":"d5_bg6"}},[_vm._m(7)]),_vm._m(8),_c('div',{staticClass:"full-ad full-ad-6 bg7",class:{'bg-loading':_vm.beforeBg7},attrs:{"id":"d5_bg7"}},[_vm._m(9)]),_vm._m(10),_c('div',{staticClass:"full-ad full-ad-7 bg8",class:{'bg-loading':_vm.beforeBg8},attrs:{"id":"d5_bg8"}},[_vm._m(11)]),_vm._m(12)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-slogon ad-slogon-2"},[_vm._v("专利 护牙"),_c('br'),_vm._v("告别硬震猛")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-cert-logo"},[_c('div',{staticClass:"ad-cert-label"},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jigsaw-ad"},[_c('div',{staticClass:"jigsaw-row method-1"},[_c('div',{staticClass:"jigsaw-block-l"},[_c('div',{staticClass:"jigsaw-texts jigsaw-texts-labeled"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("更适合 敏感牙齿")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("护牙电动牙刷")]),_c('div',{staticClass:"ad-label"},[_vm._v("3")])])]),_c('div',{staticClass:"jigsaw-block-r"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("专利 万毛牙刷")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("97%护牙不伤龈")]),_c('div',{staticClass:"ad-label"},[_vm._v("1")]),_c('div',{staticClass:"ad-label-2"},[_vm._v("2")])])])]),_c('div',{staticClass:"jigsaw-row method-2"},[_c('div',{staticClass:"jigsaw-block-l"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("自适应马达")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("护牙级净齿")])])]),_c('div',{staticClass:"jigsaw-block-r"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("风干杀菌仓")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("卫生更护牙")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body"},[_c('div',{staticClass:"ad-slogon"},[_c('span',{staticClass:"l1"},[_vm._v("97.44%")]),_c('br'),_c('span',{staticClass:"l2"},[_vm._v("护牙不伤龈")])]),_c('div',{staticClass:"ad-feature-box"},[_c('div',{staticClass:"ad-feature ad-feature-1"},[_c('div',{staticClass:"fp-1"},[_vm._v("92.31")]),_c('div',{staticClass:"fp-2"},[_vm._v("%")]),_c('div',{staticClass:"fp-3"},[_c('img',{attrs:{"src":"https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png"}})]),_c('div',{staticClass:"fp-4"},[_vm._v("受试者认同")]),_c('div',{staticClass:"fp-5"},[_vm._v("初次使用牙龈不出血")])]),_c('div',{staticClass:"ad-feature ad-feature-2"},[_c('div',{staticClass:"fp-1"},[_vm._v("97.44")]),_c('div',{staticClass:"fp-2"},[_vm._v("%")]),_c('div',{staticClass:"fp-3"},[_c('img',{attrs:{"src":"https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png"}})]),_c('div',{staticClass:"fp-4"},[_vm._v("受试者认同")]),_c('div',{staticClass:"fp-5"},[_vm._v("刷头不伤牙龈")])]),_c('div',{staticClass:"ad-feature ad-feature-3"},[_c('div',{staticClass:"fp-1"},[_vm._v("92.31")]),_c('div',{staticClass:"fp-2"},[_vm._v("%")]),_c('div',{staticClass:"fp-3"},[_c('img',{attrs:{"src":"https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png"}})]),_c('div',{staticClass:"fp-4"},[_vm._v("受试者认同")]),_c('div',{staticClass:"fp-5"},[_vm._v("使用舒适度高")])]),_c('div',{staticClass:"ad-feature ad-feature-4"},[_c('div',{staticClass:"fp-1"},[_vm._v("97.44")]),_c('div',{staticClass:"fp-2"},[_vm._v("%")]),_c('div',{staticClass:"fp-3"},[_c('img',{attrs:{"src":"https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png"}})]),_c('div',{staticClass:"fp-4"},[_vm._v("受试者认同")]),_c('div',{staticClass:"fp-5"},[_vm._v("提升刷牙适应度")])])]),_c('div',{staticClass:"ad-label"},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-2"},[_c('div',{staticClass:"ad-slogon"},[_vm._v("更软密更护龈"),_c('br'),_vm._v("10000+根刷毛")]),_c('div',{staticClass:"ad-product"},[_vm._v("食品级刷头")]),_c('div',{staticClass:"ad-desc"},[_vm._v("通过美国FDA（美国食品药品监督管理局）"),_c('br'),_vm._v("制定的标准检测")]),_c('div',{staticClass:"ad-label"},[_vm._v("3")]),_c('div',{staticClass:"ad-label ad-label-2"},[_vm._v("4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-3"},[_c('div',{staticClass:"ad-slogon"},[_vm._v("专利 植毛技术"),_c('br'),_vm._v("护牙不扎牙龈")]),_c('div',{staticClass:"ad-feature"},[_vm._v("4倍植毛密度  降低牙釉质载荷")]),_c('div',{staticClass:"ad-label-1"},[_vm._v("4")]),_c('div',{staticClass:"ad-label-2"},[_vm._v("1")]),_c('div',{staticClass:"ad-img ad-img-1"},[_c('div',{staticClass:"ad-product"},[_vm._v("素士D5云感刷")]),_c('div',{staticClass:"ad-desc"},[_vm._v("高密万根 刷毛 作用力更平均")]),_c('div',{staticClass:"ad-label-3"},[_vm._v("4")])]),_c('div',{staticClass:"ad-img ad-img-2"},[_c('div',{staticClass:"ad-product"},[_vm._v("个别一般电动牙刷")]),_c('div',{staticClass:"ad-desc"},[_vm._v("刷毛稀疏 戳刺牙齿")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-2 ad-body-4"},[_c('div',{staticClass:"ad-slogon"},[_vm._v("自适应马达"),_c('br'),_vm._v("护牙级净齿")]),_c('div',{staticClass:"ad-product"},[_vm._v("刷力自适应输出 拒绝无效刷牙")]),_c('div',{staticClass:"ad-img"},[_c('div',{staticClass:"ad-img-1"},[_c('div',{staticClass:"ad-img-txt-1"},[_vm._v("素士D5云感刷")]),_c('div',{staticClass:"ad-img-txt-2"},[_vm._v("稳定清洁每颗牙齿")])]),_c('div',{staticClass:"ad-img-2"},[_c('div',{staticClass:"ad-img-txt-1"},[_vm._v("个别一般电动牙刷")]),_c('div',{staticClass:"ad-img-txt-2"},[_vm._v("标准力度下刷丝停摆")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-3 ad-body-5"},[_c('div',{staticClass:"ad-slogon"},[_vm._v("摆幅稳控"),_c('br'),_vm._v("只刷牙不伤牙")]),_c('div',{staticClass:"ad-feature"},[_vm._v("摆向摆幅稳定 不乱甩更护龈")]),_c('div',{staticClass:"ad-img ad-img-1"},[_c('div',{staticClass:"ad-product"},[_vm._v("素士D5云感刷")]),_c('div',{staticClass:"ad-desc"},[_vm._v("摆幅稳控 精准护龈")])]),_c('div',{staticClass:"ad-img ad-img-2"},[_c('div',{staticClass:"ad-product"},[_vm._v("个别一般电动牙刷")]),_c('div',{staticClass:"ad-desc"},[_vm._v("刷毛乱甩 易伤牙龈")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jigsaw-ad jigsaw-ad-2"},[_c('div',{staticClass:"jigsaw-row method-1"},[_c('div',{staticClass:"jigsaw-block-l jigsaw-block-w"},[_c('div',{staticClass:"jigsaw-texts jigsaw-texts-labeled"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("风干杀菌仓"),_c('br'),_vm._v("一悬一挂")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("护牙更卫生")]),_c('div',{staticClass:"ad-label"},[_vm._v("3")])])])]),_c('div',{staticClass:"jigsaw-row method-2"},[_c('div',{staticClass:"jigsaw-block-l"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("杀菌率>99.9%")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("每天都用新 牙刷")]),_c('div',{staticClass:"ad-label-1"},[_vm._v("5")]),_c('div',{staticClass:"ad-label-2"},[_vm._v("6")])])]),_c('div',{staticClass:"jigsaw-block-r"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("护牙双刷头")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("旗舰级标配")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-2 ad-body-6"},[_c('div',{staticClass:"ad-product"},[_vm._v("4大专业模式")]),_c('div',{staticClass:"ad-mode ad-mode-1"},[_c('div',{staticClass:"ad-mode-icon"}),_c('div',{staticClass:"ad-mode-title"},[_vm._v("轻柔模式")]),_c('div',{staticClass:"ad-mode-sub"},[_vm._v("敏感适用 新手友好")])]),_c('div',{staticClass:"ad-mode ad-mode-2"},[_c('div',{staticClass:"ad-mode-icon"}),_c('div',{staticClass:"ad-mode-title"},[_vm._v("清洁模式")]),_c('div',{staticClass:"ad-mode-sub"},[_vm._v("日常使用")])]),_c('div',{staticClass:"ad-mode ad-mode-3"},[_c('div',{staticClass:"ad-mode-icon"}),_c('div',{staticClass:"ad-mode-title"},[_vm._v("亮白模式")]),_c('div',{staticClass:"ad-mode-sub"},[_vm._v("奶茶 咖啡党去黄")])]),_c('div',{staticClass:"ad-mode ad-mode-4"},[_c('div',{staticClass:"ad-mode-icon"}),_c('div',{staticClass:"ad-mode-title"},[_vm._v("抛光模式")]),_c('div',{staticClass:"ad-mode-sub"},[_vm._v("变频振动 抛光亮白")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jigsaw-ad jigsaw-ad-2 jigsaw-ad-3"},[_c('div',{staticClass:"jigsaw-row method-2"},[_c('div',{staticClass:"jigsaw-block-l"},[_c('div',{staticClass:"jigsaw-texts jigsaw-texts-labeled"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("充1次用半年")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("超长续航 久到忘了充电线")]),_c('div',{staticClass:"ad-label"},[_vm._v("7")])])]),_c('div',{staticClass:"jigsaw-block-r"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("告别噪音焦虑")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("感受一波沉浸式刷牙")])])])]),_c('div',{staticClass:"jigsaw-row method-1 method-1-3"},[_c('div',{staticClass:"jigsaw-block-l jigsaw-block-w"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("舒服不麻手")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("真的几乎没有震手感")])])])]),_c('div',{staticClass:"jigsaw-row method-1 method-1-4"},[_c('div',{staticClass:"jigsaw-block-l jigsaw-block-w"},[_c('div',{staticClass:"jigsaw-texts"},[_c('div',{staticClass:"jigsaw-title"},[_vm._v("IPX8巨防水")]),_c('div',{staticClass:"jigsaw-desc"},[_vm._v("边洗澡边刷牙的无限畅快")]),_c('div',{staticClass:"ad-label"},[_vm._v("8")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ad-body ad-body-2"},[_c('div',{staticClass:"ad-product"},[_vm._v("产品参数")]),_c('div',{staticClass:"ad-features"},[_c('div',{staticClass:"ad-feature"},[_c('div',{staticClass:"ad-feature-text"},[_vm._v("D5电动牙刷机身x1")]),_c('div',{staticClass:"ad-label"},[_vm._v("1")]),_c('div',{staticClass:"ad-circle"})]),_c('div',{staticClass:"ad-feature"},[_c('div',{staticClass:"ad-feature-text"},[_vm._v("风干杀菌仓x1"),_c('span',{staticClass:"ad-feature-sub-text1"},[_vm._v("（含壁挂件*1）")])]),_c('div',{staticClass:"ad-label"},[_vm._v("2")]),_c('div',{staticClass:"ad-circle"})]),_c('div',{staticClass:"ad-feature"},[_c('div',{staticClass:"ad-feature-text"},[_vm._v("云感刷头x1 云感亮白刷头x1")]),_c('div',{staticClass:"ad-label"},[_vm._v("3")]),_c('div',{staticClass:"ad-circle"})]),_c('div',{staticClass:"ad-feature"},[_c('div',{staticClass:"ad-feature-text"},[_vm._v("全面口腔呵护礼盒"),_c('div',{staticClass:"ad-feature-sub-text2"},[_vm._v("（牙线棒x20支 漱口水x4颗 口腔清洁泡泡膏x1支）")])]),_c('div',{staticClass:"ad-label"},[_vm._v("4")]),_c('div',{staticClass:"ad-circle"})])]),_c('div',{staticClass:"ad-special-desc"},[_vm._v("*指原:素士高光液体牙膏,新旧版本随机发货")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jigsaw-ad jigsaw-ad-4 jigsaw-desc"},[_c('p',[_vm._v("注释")]),_c('p',[_vm._v("1. 实用新型专利号：ZL 2017 2 0473306.8")]),_c('p',[_vm._v("2. 数据来源于素士实验室，指39名受试者使用素士云感刷头后自我评估数据，仅供参考，实际效果因人而异。")]),_c('p',[_vm._v("3. 相较于素士电动牙刷品类。")]),_c('p',[_vm._v("4. 数据来自第三方检测机构，与素士果冻亮白刷头对比，素士云感刷头植毛量提升4.68倍，总植毛数量约为10493根，不同批次产品可能存在合理误差。")]),_c('p',[_vm._v("5. 数据来源于独立第三方检验检测机构出具的《检测报告》，检测报告编号：WJ20231892。检测菌种为：大肠杆菌 8099、变形链球菌 ATCC35668、 金黄色葡萄球ATCC6538、白色念珠菌 ATCC10231。不同菌种杀菌率可能存在差异。")]),_c('p',[_vm._v("6. 为广告创意表达，指牙刷刷头经过风干和杀菌步骤达到焕然一新的感受，不代表全新牙刷。")]),_c('p',[_vm._v("7. 数据来自素士实验室，指在充满电的状态下，以清洁模式计算，每天使用2次，每次2分钟，约可用180天。")]),_c('p',[_vm._v("8. 数据来源于国家数字电子产品质量检验检测中心与深圳市计量质量检测研究院联合出具的 《检验报告》。 报告编号为：EGZ2306210121R00401R。")]),_c('p',[_vm._v("9. 检测报告编码:CANEC2308709702。")])])
}]

export { render, staticRenderFns }