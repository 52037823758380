import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './route'
import store from './store'

import axios from 'axios'

import promise from 'es6-promise'
import 'amfe-flexible'

import {
  Button ,
  Icon ,
  Row ,
  Col ,
  Menu ,
  message ,
  Modal
} from 'ant-design-vue'
import 'animate.css'
import 'normalize.css'

import VueLazyload from 'vue-lazyload'

// js/css extensions
import './static/css/index.css'
import './static/css/common.css'

promise.polyfill()

Vue.config.productionTip = false;
Vue.component(Button.name, Button);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Menu.name, Menu);
Vue.component(Menu.Item.name, Menu.Item);
Vue.component(Modal);
Vue.component(Icon.name, Icon);

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'https://file.soocas.com/sg9_resource/soocas-site/common/unhappy-white.png',
    loading: 'https://file.soocas.com/sg9_resource/soocas-site/common/bg-silver.png',
    attempt: 1
});

Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;
message.config({
    duration: 2,
    top: "1rem",
    maxCount: 1
});

Vue.prototype.$axios = axios;
Vue.prototype.$elVisible = function(uniqueSelector) {
    let el = document.querySelector(uniqueSelector);
    if(!el) return false;
    let rect = el.getBoundingClientRect();
    return ((rect.top >= 0 && rect.top <= window.innerHeight)
        || (rect.bottom >= 0 && rect.bottom <= window.innerHeight));
};

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }else {
    document.title = '素士官网'
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

