<template>
  <div id="app">
    <AppHeader></AppHeader>
    <router-view :key="key"/>
    <AppFooter></AppFooter>
    <CopyRight></CopyRight>
  </div>
</template>
<script>
import { isLessIE10 } from './static/js/lessIE10.js';
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import CopyRight from "./components/CopyRight.vue";

export default {
  name:"App",
  components: {
    AppHeader,
    AppFooter,
    CopyRight
  },
  computed:{
    key(){
      return this.$route.path + Math.random();
    }
  },
  mounted(){
    if (isLessIE10) {
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
  }
}
</script>

<style lang="less">
</style>
