<template>
    <div class="body subscribe-box">
        <div class="sb-box sb-box-1">
            <div class="sb-content-1">
                <div class="sbc1-s1">关注我们</div>
                <div class="sbc1-s2"><span class="font2">素士全网覆盖<span class="font1">350万+</span>粉丝<br>欢迎了解更多素士</span></div>
            </div>
            <div class="sb-content-2">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="content1">
                    <div class="img img-1"></div>
                    <div class="scope scope-1">微信公众号</div>
                    <div class="attempt attempt-1">@素士</div>

                    <div class="img img-2"></div>
                    <div class="scope scope-2">官方微博</div>
                    <div class="attempt attempt-2">@素士SOOCAS</div>

                    <div class="img img-3"></div>
                    <div class="scope scope-3">小红书</div>
                    <div class="attempt attempt-3">@素士SOOCAS</div>

                    <div class="img img-4"></div>
                    <div class="scope scope-4">抖音</div>
                    <div class="attempt attempt-4">@素士官方旗舰店</div>

                    <div class="img img-5"></div>
                    <div class="scope scope-5">天猫旗舰店</div>
                    <div class="attempt attempt-5">@素士旗舰店</div>

                    <div class="img img-6"></div>
                    <div class="scope scope-6">京东旗舰店</div>
                    <div class="attempt attempt-6">@素士个人护理京东自营旗舰店</div>
                </div>
            </div>
        </div>
        <div class="sb-box sb-box-2"></div>
    </div>
</template>

<script>
export default {
    name: 'Subscribe',
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="less" scoped>
.subscribe-box {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    height: 83.10547vw;

    .sb-box {
        position: absolute;
        left: 0;
        width: 100%;
    }

    .sb-box-1 {
        height: 41.99219vw;
        top: 0;

        .sb-content-1 {
            position: absolute;
            width: 30.15625vw;
            height: 12.07031vw;
            top: 6.75781vw;
            left: 34.92188vw;

            .sbc1-s1 {
                position: absolute;
                width: 14.0625vw;
                height: 4.10156vw;
                top: 0vw;
                left: 8.04688vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.51563vw;
                font-weight: 400;
                line-height: 4.10156vw;
                letter-spacing: 0em;
                color: #000000;
            }
            .sbc1-s2 {
                position: absolute;
                width: 30.15625vw;
                height: 7.1875vw;
                top: 4.88281vw;
                left: 0vw;
                text-align: center;

                .font1 {
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 3.28125vw;
                    font-weight: 400;
                    line-height: 3.4375vw;
                    letter-spacing: 0em;
                    color: #F54829;
                }
                .font2 {
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 2.34375vw;
                    font-weight: 400;
                    line-height: 3.4375vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
            }
        }

        .sb-content-2 {
            position: absolute;
            width: 57.57813vw;
            height: 15.39063vw;
            top: 23.55469vw;
            left: 21.21094vw;

            .line1{
                position: absolute;
                width: 57.26563vw;
                height: 0.07813vw;
                top: 0vw;
                left: 0.15625vw;
                background: #D9D9D9;
            }

            .line2{
                position: absolute;
                width: 57.26563vw;
                height: 0.07813vw;
                top: 15.3125vw;
                left: 0.15625vw;
                background: #D9D9D9;
            }

            .content1{
                position: absolute;
                width: 57.57813vw;
                height: 8.67188vw;
                top: 3.35938vw;
                left: 0vw;

                .img {
                    position: absolute;
                    width: 4.375vw;
                    height: 4.375vw;
                    top: 0vw;
                    background-position: center center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .scope {
                    position: absolute;
                    height: 1.36719vw;
                    top: 5.74219vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 1.36719vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
                .attempt {
                    position: absolute;
                    height: 1.36719vw;
                    top: 7.30469vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.9375vw;
                    font-weight: 400;
                    line-height: 1.36719vw;
                    letter-spacing: 0em;
                    color: rgba(0,0,0,0.6);
                }

                .img-1 {
                    left: 0.9375vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/1-wechat.png");
                }
                .scope-1 {
                    width: 6.25vw;
                    left: 0vw;
                }
                .attempt-1 {
                    width: 2.92969vw;
                    left: 1.67969vw;
                }

                .img-2 {
                    left: 10.03906vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/2-weibo.png");
                }
                .scope-2 {
                    width: 5vw;
                    left: 9.72656vw;
                }
                .attempt-2 {
                    width: 7.34375vw;
                    left: 8.55469vw;
                }

                .img-3 {
                    left: 19.6875vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/3-red.png");
                }
                .scope-3 {
                    width: 3.75vw;
                    left: 20vw;
                }
                .attempt-3 {
                    width: 7.34375vw;
                    left: 18.20313vw;
                }

                .img-4 {
                    left: 29.49219vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/4-douyin.png");
                }
                .scope-4 {
                    width: 2.5vw;
                    left: 30.42969vw;
                }
                .attempt-4 {
                    width: 7.61719vw;
                    left: 27.85156vw;
                }

                .img-5 {
                    left: 38.71094vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/5-tmall.png");
                }
                .scope-5 {
                    width: 6.25vw;
                    left: 37.77344vw;
                }
                .attempt-5 {
                    width: 5.74219vw;
                    left: 38.04688vw;
                }

                .img-6 {
                    left: 49.76563vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/6-jd.png");
                }
                .scope-6 {
                    width: 6.25vw;
                    left: 48.82813vw;
                }
                .attempt-6 {
                    width: 11.25vw;
                    left: 46.32813vw;
                }
                .attempt.attempt-6 {
                    font-size: 0.78125vw;
                }
            }
        }
    }
    .sb-box-2 {
        height: 41.11328vw;
        top: 41.99219vw;
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/subscribe/oral-back-composed_nobg_orange-logo.png");
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
</style>