<template>
    <div class="body d5-body">
        <!-- full-width AD part 1 -->
        <div id="d5_bg1" class="full-ad bg1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body ad-body-2">
                <div class="ad-product">素士D5云感刷</div>
                <div class="ad-slogon ad-slogon-2">专利 护牙<br>告别硬震猛</div>
                <div class="ad-button" @click="playVid">观看视频 &gt;</div>
                <div class="ad-label">1</div>
            </div>
            <div class="ad-cert-logo">
                <div class="ad-cert-label">2</div>
            </div>
            <div class="ad-cert-text">敏牙专研</div>
            <div class="ad-video">
                <div style="width:100%;height:100%;">
                    <video id="vid001" controls class="video" muted="muted" preload="">
                        <source src="https://file.soocas.com/sg9_resource/soocas-site/detail-d5/tmall_d5_vid_20231207.mp4" type="video/mp4">
                    </video>
                    <div class="ad-close-video" @click="exitFullScreen"></div>
                </div>
            </div>
        </div>

        <!-- jigsaw AD part 1 -->
        <div class="jigsaw-ad">
            <div class="jigsaw-row method-1">
                <div class="jigsaw-block-l">
                    <div class="jigsaw-texts jigsaw-texts-labeled">
                        <div class="jigsaw-title">更适合 敏感牙齿</div>
                        <div class="jigsaw-desc">护牙电动牙刷</div>
                        <div class="ad-label">3</div>
                    </div>
                </div>
                <div class="jigsaw-block-r">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">专利 万毛牙刷</div>
                        <div class="jigsaw-desc">97%护牙不伤龈</div>
                        <div class="ad-label">1</div>
                        <div class="ad-label-2">2</div>
                    </div>
                </div>
            </div>
            <div class="jigsaw-row method-2">
                <div class="jigsaw-block-l">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">自适应马达</div>
                        <div class="jigsaw-desc">护牙级净齿</div>
                    </div>
                </div>
                <div class="jigsaw-block-r">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">风干杀菌仓</div>
                        <div class="jigsaw-desc">卫生更护牙</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- full-width AD part 2 -->
        <div id="d5_bg2" class="full-ad full-ad-1 bg2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-slogon"><span class="l1">97.44%</span><br><span class="l2">护牙不伤龈</span></div>
                <div class="ad-feature-box">
                    <div class="ad-feature ad-feature-1">
                        <div class="fp-1">92.31</div>
                        <div class="fp-2">%</div>
                        <div class="fp-3"><img src="https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png" /></div>
                        <div class="fp-4">受试者认同</div>
                        <div class="fp-5">初次使用牙龈不出血</div>
                    </div>
                    <div class="ad-feature ad-feature-2">
                        <div class="fp-1">97.44</div>
                        <div class="fp-2">%</div>
                        <div class="fp-3"><img src="https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png" /></div>
                        <div class="fp-4">受试者认同</div>
                        <div class="fp-5">刷头不伤牙龈</div>
                    </div>
                    <div class="ad-feature ad-feature-3">
                        <div class="fp-1">92.31</div>
                        <div class="fp-2">%</div>
                        <div class="fp-3"><img src="https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png" /></div>
                        <div class="fp-4">受试者认同</div>
                        <div class="fp-5">使用舒适度高</div>
                    </div>
                    <div class="ad-feature ad-feature-4">
                        <div class="fp-1">97.44</div>
                        <div class="fp-2">%</div>
                        <div class="fp-3"><img src="https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03-arrow.png" /></div>
                        <div class="fp-4">受试者认同</div>
                        <div class="fp-5">提升刷牙适应度</div>
                    </div>
                </div>
                <div class="ad-label">2</div>
            </div>
        </div>

        <div id="d5_bg3" class="full-ad full-ad-2 bg3" :class="{'bg-loading':beforeBg3}">
            <div class="ad-body ad-body-2">
                <div class="ad-slogon">更软密更护龈<br>10000+根刷毛</div>
                <div class="ad-product">食品级刷头</div>
                <div class="ad-desc">通过美国FDA（美国食品药品监督管理局）<br>制定的标准检测</div>
                <div class="ad-label">3</div>
                <div class="ad-label ad-label-2">4</div>
            </div>
        </div>

        <div id="d5_bg4" class="full-ad full-ad-3 bg4" :class="{'bg-loading':beforeBg4}">
            <div class="ad-body ad-body-3">
                <div class="ad-slogon">专利 植毛技术<br>护牙不扎牙龈</div>
                <div class="ad-feature">4倍植毛密度&nbsp;&nbsp;降低牙釉质载荷</div>
                <div class="ad-label-1">4</div>
                <div class="ad-label-2">1</div>
                <div class="ad-img ad-img-1">
                    <div class="ad-product">素士D5云感刷</div>
                    <div class="ad-desc">高密万根 刷毛 作用力更平均</div>
                    <div class="ad-label-3">4</div>
                </div>
                <div class="ad-img ad-img-2">
                    <div class="ad-product">个别一般电动牙刷</div>
                    <div class="ad-desc">刷毛稀疏 戳刺牙齿</div>
                </div>
            </div>
        </div>

        <div id="d5_bg5" class="full-ad full-ad-2 full-ad-4 bg5" :class="{'bg-loading':beforeBg5}">
            <div class="ad-body ad-body-2 ad-body-4">
                <div class="ad-slogon">自适应马达<br>护牙级净齿</div>
                <div class="ad-product">刷力自适应输出 拒绝无效刷牙</div>
                <div class="ad-img">
                    <div class="ad-img-1">
                        <div class="ad-img-txt-1">素士D5云感刷</div>
                        <div class="ad-img-txt-2">稳定清洁每颗牙齿</div>
                    </div>
                    <div class="ad-img-2">
                        <div class="ad-img-txt-1">个别一般电动牙刷</div>
                        <div class="ad-img-txt-2">标准力度下刷丝停摆</div>
                    </div>
                </div>
            </div>
        </div>

        <div id="d5_bg6" class="full-ad full-ad-3 full-ad-5 bg6" :class="{'bg-loading':beforeBg6}">
            <div class="ad-body ad-body-3 ad-body-5">
                <div class="ad-slogon">摆幅稳控<br>只刷牙不伤牙</div>
                <div class="ad-feature">摆向摆幅稳定  不乱甩更护龈</div>
                <div class="ad-img ad-img-1">
                    <div class="ad-product">素士D5云感刷</div>
                    <div class="ad-desc">摆幅稳控 精准护龈</div>
                </div>
                <div class="ad-img ad-img-2">
                    <div class="ad-product">个别一般电动牙刷</div>
                    <div class="ad-desc">刷毛乱甩 易伤牙龈</div>
                </div>
            </div>
        </div>

        <!-- jigsaw AD part 2 -->
        <div class="jigsaw-ad jigsaw-ad-2">
            <div class="jigsaw-row method-1">
                <div class="jigsaw-block-l jigsaw-block-w">
                    <div class="jigsaw-texts jigsaw-texts-labeled">
                        <div class="jigsaw-title">风干杀菌仓<br>一悬一挂</div>
                        <div class="jigsaw-desc">护牙更卫生</div>
                        <div class="ad-label">3</div>
                    </div>
                </div>
            </div>
            <div class="jigsaw-row method-2">
                <div class="jigsaw-block-l">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">杀菌率>99.9%</div>
                        <div class="jigsaw-desc">每天都用新 牙刷</div>
                        <div class="ad-label-1">5</div>
                        <div class="ad-label-2">6</div>
                    </div>
                </div>
                <div class="jigsaw-block-r">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">护牙双刷头</div>
                        <div class="jigsaw-desc">旗舰级标配</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- full-width AD part 3 -->
        <div id="d5_bg7" class="full-ad full-ad-6 bg7" :class="{'bg-loading':beforeBg7}">
            <div class="ad-body ad-body-2 ad-body-6">
                <div class="ad-product">4大专业模式</div>
                <div class="ad-mode ad-mode-1">
                    <div class="ad-mode-icon"></div>
                    <div class="ad-mode-title">轻柔模式</div>
                    <div class="ad-mode-sub">敏感适用 新手友好</div>
                </div>
                <div class="ad-mode ad-mode-2">
                    <div class="ad-mode-icon"></div>
                    <div class="ad-mode-title">清洁模式</div>
                    <div class="ad-mode-sub">日常使用</div>
                </div>
                <div class="ad-mode ad-mode-3">
                    <div class="ad-mode-icon"></div>
                    <div class="ad-mode-title">亮白模式</div>
                    <div class="ad-mode-sub">奶茶 咖啡党去黄</div>
                </div>
                <div class="ad-mode ad-mode-4">
                    <div class="ad-mode-icon"></div>
                    <div class="ad-mode-title">抛光模式</div>
                    <div class="ad-mode-sub">变频振动 抛光亮白</div>
                </div>
            </div>
        </div>

        <!-- jigsaw AD part 3 -->
        <div class="jigsaw-ad jigsaw-ad-2 jigsaw-ad-3">
            <div class="jigsaw-row method-2">
                <div class="jigsaw-block-l">
                    <div class="jigsaw-texts jigsaw-texts-labeled">
                        <div class="jigsaw-title">充1次用半年</div>
                        <div class="jigsaw-desc">超长续航 久到忘了充电线</div>
                        <div class="ad-label">7</div>
                    </div>
                </div>
                <div class="jigsaw-block-r">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">告别噪音焦虑</div>
                        <div class="jigsaw-desc">感受一波沉浸式刷牙</div>
                    </div>
                </div>
            </div>
            <div class="jigsaw-row method-1 method-1-3">
                <div class="jigsaw-block-l jigsaw-block-w">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">舒服不麻手</div>
                        <div class="jigsaw-desc">真的几乎没有震手感</div>
                    </div>
                </div>
            </div>
            <div class="jigsaw-row method-1 method-1-4">
                <div class="jigsaw-block-l jigsaw-block-w">
                    <div class="jigsaw-texts">
                        <div class="jigsaw-title">IPX8巨防水</div>
                        <div class="jigsaw-desc">边洗澡边刷牙的无限畅快</div>
                        <div class="ad-label">8</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- full-width AD part 4 -->
        <div id="d5_bg8" class="full-ad full-ad-7 bg8" :class="{'bg-loading':beforeBg8}">
            <div class="ad-body ad-body-2">
                <div class="ad-product">产品参数</div>
                <div class="ad-features">
                    <div class="ad-feature">
                        <div class="ad-feature-text">D5电动牙刷机身x1</div>
                        <div class="ad-label">1</div>
                        <div class="ad-circle"></div>
                    </div>
                    <div class="ad-feature">
                        <div class="ad-feature-text">风干杀菌仓x1<span class="ad-feature-sub-text1">（含壁挂件*1）</span></div>
                        <div class="ad-label">2</div>
                        <div class="ad-circle"></div>
                    </div>
                    <div class="ad-feature">
                        <div class="ad-feature-text">云感刷头x1  云感亮白刷头x1</div>
                        <div class="ad-label">3</div>
                        <div class="ad-circle"></div>
                    </div>
                    <div class="ad-feature">
                        <div class="ad-feature-text">全面口腔呵护礼盒<div class="ad-feature-sub-text2">（牙线棒x20支  漱口水x4颗  口腔清洁泡泡膏x1支）</div></div>
                        <div class="ad-label">4</div>
                        <div class="ad-circle"></div>
                    </div>
                </div>
                <div class="ad-special-desc">*指原:素士高光液体牙膏,新旧版本随机发货</div>
            </div>
        </div>

        <!-- jigsaw AD part 3 -->
        <div class="jigsaw-ad jigsaw-ad-4 jigsaw-desc">
            <p>注释</p>
            <p>1. 实用新型专利号：ZL 2017 2 0473306.8</p>
            <p>2. 数据来源于素士实验室，指39名受试者使用素士云感刷头后自我评估数据，仅供参考，实际效果因人而异。</p>
            <p>3. 相较于素士电动牙刷品类。</p>
            <p>4. 数据来自第三方检测机构，与素士果冻亮白刷头对比，素士云感刷头植毛量提升4.68倍，总植毛数量约为10493根，不同批次产品可能存在合理误差。</p>
            <p>5. 数据来源于独立第三方检验检测机构出具的《检测报告》，检测报告编号：WJ20231892。检测菌种为：大肠杆菌 8099、变形链球菌 ATCC35668、 金黄色葡萄球ATCC6538、白色念珠菌 ATCC10231。不同菌种杀菌率可能存在差异。</p>
            <p>6. 为广告创意表达，指牙刷刷头经过风干和杀菌步骤达到焕然一新的感受，不代表全新牙刷。</p>
            <p>7. 数据来自素士实验室，指在充满电的状态下，以清洁模式计算，每天使用2次，每次2分钟，约可用180天。</p>
            <p>8. 数据来源于国家数字电子产品质量检验检测中心与深圳市计量质量检测研究院联合出具的 《检验报告》。 报告编号为：EGZ2306210121R00401R。</p>
            <p>9. 检测报告编码:CANEC2308709702。</p>
        </div>

    </div>
</template>

<script>

export default {
    name: 'D5',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true,
            beforeBg3: true,
            beforeBg4: true,
            beforeBg5: true,
            beforeBg6: true,
            beforeBg7: true,
            beforeBg8: true,
            supportFullscreen: true,
            isFullscreen: false
        };
    },
    mounted() {
        this.doLazyBinding();
        this.doFullscreenBinding();
        window.debug1 = this;
    },
    methods: {
        doFullscreenBinding() {
            let handleFullScreenChange = function(){
                let element = document.querySelector(".ad-video");
                if(element.className.indexOf("fullscreen-size") == -1) {
                    element.className = "ad-video fullscreen-size";
                } else {
                    element.className = "ad-video";
                }
                console.log("handleFullScreenChange");
                let video = document.querySelector("#vid001");
                video.currentTime = 0;
                video.play();
            };
            document.addEventListener('fullscreenchange', handleFullScreenChange);
            document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.addEventListener('mozfullscreenchange', handleFullScreenChange);
            document.addEventListener('MSFullscreenChange', handleFullScreenChange);
        },
        playVid() {
            this.requestFullscreen(document.querySelector(".ad-video"));
            //let video = document.querySelector("#vid001");
            //video.currentTime = 0;
            //video.play();
        },
        requestFullscreen(element) {
            if(!this.supportFullscreen) {
                this.simpleRequestFullScreen();
                return;
            }
            if (element.requestFullscreen) {
                element.requestFullscreen();
                this.isFullscreen = true;
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
                this.isFullscreen = true;
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
                this.isFullscreen = true;
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
                this.isFullscreen = true;
            } else {
                this.supportFullscreen = false;
                // alert("浏览器不支持全屏功能。");
            }
        },
        simpleRequestFullScreen() {
            let element = document.querySelector(".ad-video");
            element.className = "ad-video fullscreen-video";
            let video = document.querySelector("#vid001");
            video.currentTime = 0;
            video.play();
        },
        exitFullScreen() {
            if(!this.supportFullscreen) {
                this.simpleExitFullScreen();
                return;
            }
            if (document.exitFullscreen) {
                document.exitFullscreen();
                this.isFullscreen = false;
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
                this.isFullscreen = false;
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
                this.isFullscreen = false;
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
                this.isFullscreen = false;
            } else {
                this.supportFullscreen = false;
                // alert("浏览器不支持全屏功能。");
            }
        },
        simpleExitFullScreen() {
            let element = document.querySelector(".ad-video");
            element.className = "ad-video";
            let video = document.querySelector("#vid001");
            video.pause();
            video.currentTime = 0;
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
                _this.checkBg3();
                _this.checkBg4();
                _this.checkBg5();
                _this.checkBg6();
                _this.checkBg7();
                _this.checkBg8();
            };
            _this.checkBg1();
            _this.checkBg2();
            _this.checkBg3();
            _this.checkBg4();
            _this.checkBg5();
            _this.checkBg6();
            _this.checkBg7();
            _this.checkBg8();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#d5_bg1")) {
                    this.beforeBg1 = false;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#d5_bg2")) {
                    this.beforeBg2 = false;
                }
            }
        },
        checkBg3() {
            if(this.beforeBg3) {
                if(this.$elVisible("#d5_bg3")) {
                    this.beforeBg3 = false;
                }
            }
        },
        checkBg4() {
            if(this.beforeBg4) {
                if(this.$elVisible("#d5_bg4")) {
                    this.beforeBg4 = false;
                }
            }
        },
        checkBg5() {
            if(this.beforeBg5) {
                if(this.$elVisible("#d5_bg5")) {
                    this.beforeBg5 = false;
                }
            }
        },
        checkBg6() {
            if(this.beforeBg6) {
                if(this.$elVisible("#d5_bg6")) {
                    this.beforeBg6 = false;
                }
            }
        },
        checkBg7() {
            if(this.beforeBg7) {
                if(this.$elVisible("#d5_bg7")) {
                    this.beforeBg7 = false;
                }
            }
        },
        checkBg8() {
            if(this.beforeBg8) {
                if(this.$elVisible("#d5_bg8")) {
                    this.beforeBg8 = false;
                }
            }
        } // End
    }
}
</script>

<style lang="less" scoped>
.d5-body .full-ad,
.d5-body .full-ad .ad-cert-logo,
.d5-body .full-ad .ad-video .ad-close-video,
.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l,
.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-r,
.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-w,
.d5-body .full-ad .ad-body .ad-img,
.d5-body .full-ad .ad-body .ad-mode .ad-mode-icon {
    background-size:100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}
.d5-body .full-ad.full-ad-3.bg4 .ad-body.ad-body-3 .ad-img.ad-img-1,
.d5-body .full-ad.full-ad-3.bg4 .ad-body.ad-body-3 .ad-img.ad-img-2 {
    background-size:100% auto;
    background-position: center top;
}
.d5-body .full-ad.full-ad-3.full-ad-5.bg6 .ad-body.ad-body-3.ad-body-5 .ad-img.ad-img-1{
    background-size:auto 100%;
    background-position: left center;
}
.d5-body .full-ad.full-ad-3.full-ad-5.bg6 .ad-body.ad-body-3.ad-body-5 .ad-img.ad-img-2{
    background-size:auto 100%;
    background-position: right center;
}

.d5-body .full-ad.bg1 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/01.jpg");
}
.d5-body .full-ad.bg1 .ad-cert-logo {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/01-d5-stamp.png");
}
.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-l{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/02-1.jpg");
}
.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-r {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/02-2.jpg");
}
.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-l{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/02-3.jpg");
}
.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-r{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/02-4.jpg");
}
.d5-body .full-ad.full-ad-1.bg2{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/03.jpg");
}
.d5-body .full-ad.full-ad-2.bg3{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/04.jpg");
}
.d5-body .full-ad.full-ad-3.bg4{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/05.jpg");
}
.d5-body .full-ad.full-ad-3.bg4 .ad-body.ad-body-3 .ad-img.ad-img-1 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/05-2.png");
}
.d5-body .full-ad.full-ad-3.bg4 .ad-body.ad-body-3 .ad-img.ad-img-2 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/05-3.png");
}
.d5-body .full-ad.full-ad-2.full-ad-4.bg5 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/06.jpg");
}
.d5-body .full-ad.full-ad-2.full-ad-4.bg5 .ad-body.ad-body-2.ad-body-4 .ad-img {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/06-animate.gif");
}
.d5-body .full-ad.full-ad-3.full-ad-5.bg6 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/07.jpg");
}
.d5-body .full-ad.full-ad-3.full-ad-5.bg6 .ad-body.ad-body-3.ad-body-5 .ad-img.ad-img-1 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/07-animate.gif");
}
.d5-body .full-ad.full-ad-3.full-ad-5.bg6 .ad-body.ad-body-3.ad-body-5 .ad-img.ad-img-2 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/07-animate.gif");
}
.d5-body .jigsaw-ad.jigsaw-ad-2 .jigsaw-row.method-1 .jigsaw-block-w{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/08-1.jpg");
}
.d5-body .jigsaw-ad.jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/08-2.gif");
}
.d5-body .jigsaw-ad.jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-r{
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/08-3.jpg");
}
.d5-body .full-ad.full-ad-6.bg7 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/09.jpg");
}
.d5-body .full-ad.full-ad-6.bg7 .ad-body.ad-body-2.ad-body-6 .ad-mode.ad-mode-1 .ad-mode-icon {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/09-1.png");
}
.d5-body .full-ad.full-ad-6.bg7 .ad-body.ad-body-2.ad-body-6 .ad-mode.ad-mode-2 .ad-mode-icon {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/09-2.png");
}
.d5-body .full-ad.full-ad-6.bg7 .ad-body.ad-body-2.ad-body-6 .ad-mode.ad-mode-3 .ad-mode-icon {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/09-3.png");
}
.d5-body .full-ad.full-ad-6.bg7 .ad-body.ad-body-2.ad-body-6 .ad-mode.ad-mode-4 .ad-mode-icon {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/09-4.png");
}
.d5-body .jigsaw-ad.jigsaw-ad-2.jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/10-1.jpg");
}
.d5-body .jigsaw-ad.jigsaw-ad-2.jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-r {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/10-2.jpg");
}
.d5-body .jigsaw-ad.jigsaw-ad-2.jigsaw-ad-3 .jigsaw-row.method-1.method-1-3 .jigsaw-block-w {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/10-3.jpg");
}
.d5-body .jigsaw-ad.jigsaw-ad-2.jigsaw-ad-3 .jigsaw-row.method-1.method-1-4 .jigsaw-block-w {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/10-4.jpg");
}
.d5-body .full-ad.full-ad-7.bg8 {
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/detail-d5/11.jpg");
}

.d5-body .full-ad.bg1.bg-loading,
.d5-body .full-ad.full-ad-1.bg2.bg-loading,
.d5-body .full-ad.full-ad-2.bg3.bg-loading,
.d5-body .full-ad.full-ad-3.bg4.bg-loading,
.d5-body .full-ad.full-ad-2.full-ad-4.bg5.bg-loading,
.d5-body .full-ad.full-ad-3.full-ad-5.bg6.bg-loading,
.d5-body .full-ad.full-ad-6.bg7.bg-loading,
.d5-body .full-ad.full-ad-7.bg8.bg-loading {
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-loading.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.d5-body .jigsaw-block-l,
.d5-body .jigsaw-block-m,
.d5-body .jigsaw-block-r {
	border-radius: 0.74219vw;
}

.d5-body .full-ad,
.d5-body .jigsaw-ad {
	color: #000000;
}

.d5-body .full-ad .ad-body.ad-body-2 {
	left: 21.36719vw;
	top: 13.35938vw;
}

.d5-body .full-ad .ad-cert-logo {
	position: absolute;
	left: 74.14063vw;
	top: 23.04688vw;
	width: 4.49219vw;
	height: 4.49219vw;

	.ad-cert-label{
        position: absolute;
        width: 0.27344vw;
        height: 0.54688vw;
        top: 3.75vw;
        left: 3.90625vw;
        font-family: FZLanTingHeiS-R-GB;
        font-size: 0.42969vw;
        font-weight: 400;
        line-height: 0.54688vw;
        letter-spacing: 0em;
        color: #3695E7;
	}
}

.d5-body .full-ad .ad-cert-text {
	position: absolute;
	left: 74.10156vw;
	top: 27.30469vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.13281vw;
	font-weight: 400;
	line-height: 3.32031vw;
	color: #3695E7;
}

.d5-body .full-ad .ad-video {
    position: relative;
    display: block;
    left: 0vw;
    top: 0vw;
    width: 0vw;
    height: 0vw;
    overflow: hidden;
    /*
    display: flex;
    justify-content: center;
    align-items: center;
    */


    video {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: transparent;
    }

    .ad-close-video {
        position: absolute;
        right: 2vw;
        top: 2vw;
        width: 3vw;
        height: 3vw;
        cursor: pointer;
        background-color: #000000;
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/close-btn.png");
        z-index: 11;
        overflow: hidden;
        border-radius: 1.5vw;
    }

}

.d5-body .full-ad .ad-video.fullscreen-size {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000000;
}

.d5-body .full-ad .ad-video.fullscreen-video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    z-index: 99999;
}

.d5-body .full-ad .ad-video.fullscreen-video .ad-close-video {
    width: 10vw;
    height: 10vw;
}

.d5-body .full-ad .ad-body.ad-body-2 .ad-product {
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.56250vw;
	font-weight: 400;
	line-height: 3.32031vw;
	color: #000000;
}

.d5-body .full-ad .ad-body.ad-body-2 .ad-slogon {
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.12500vw;
	font-weight: 400;
	line-height: 3.32031vw;
}

.d5-body .full-ad .ad-body.ad-body-2 .ad-slogon.ad-slogon-2 {
	margin-top: 0.85938vw;
}

.d5-body .full-ad .ad-body.ad-body-2 .ad-button {
	margin-top: 3.86719vw;
	width: 6.48438vw;
	height: 2.81250vw;
	border-radius: 0.39063vw;
	background-color: #000000;
	color: #ffffff;
	font-family: FZLanTingHei-M-GBK;
	font-size: 0.74219vw;
	font-weight: 400;
	line-height: 2.81250vw;
}

.d5-body .full-ad .ad-body.ad-body-2 .ad-label {
	position: absolute;
	top: 4.02344vw;
	left: 6.40625vw;
	min-width: 0.39063vw;
	height: 0.78125vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.62500vw;
	font-weight: 400;
	line-height: 0.78125vw;
}

.d5-body .full-ad.full-ad-1,
.d5-body .full-ad.full-ad-2,
.d5-body .full-ad.full-ad-4,
.d5-body .full-ad.full-ad-5,
.d5-body .full-ad.full-ad-6 {
	height: 47.92500vw;
}

.d5-body .full-ad.full-ad-3 {
	height: 47.92969vw;
}

.d5-body .full-ad.full-ad-1,
.d5-body .full-ad.full-ad-2,
.d5-body .full-ad.full-ad-3 {
	margin-bottom: 1.64531vw;
}

/* Full-2 :: AD-1 */

.d5-body .full-ad.full-ad-1 .ad-body {
	position: relative;
	width: 22.96875vw;
	height: 20.07813vw;
	top: 13.90625vw;
	left: 55.66406vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-slogon {
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 3.75000vw;
	height: 7.50000vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-slogon .l1 {
	letter-spacing: -0.05em;
}
.d5-body .full-ad.full-ad-1 .ad-body .ad-slogon .l2 {
	letter-spacing: 0em;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-label {
	position: absolute;
	width: 0.39063vw;
	height: 0.78125vw;
	top: 0.00000vw;
	left: 12.77344vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.62500vw;
	font-weight: 400;
	line-height: 0.78125vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature-box {
	position: relative;
	top: auto;
	left: auto;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature {
	position: absolute;
	height: 4.06250vw;
	width: 9.60938vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature-1 {
	width: 10.78125vw;
	top: 3.20313vw;
	left: 0.00000vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature-2 {
	top: 3.20313vw;
	left: 13.35938vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature-3 {
	top: 8.51563vw;
	left: 0.00000vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature-4 {
	top: 8.51563vw;
	left: 13.35938vw;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-1 {
	position: absolute;
	width: 4.84375vw;
	height: 1.87500vw;
	top: 0.46875vw;
	left: 0.00000vw;
	font-family: FZLanTingHei-L-GBK-M;
	font-size: 1.87500vw;
	font-weight: 400;
	line-height: 1.87500vw;
	letter-spacing: -0.04em;
	color: #0078FF;
}

.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-2 {
	position: absolute;
	width: 1.25000vw;
	height: 1.28906vw;
	top: 0.93750vw;
	left: 4.64844vw;
	font-family: FZLanTingHei-L-GBK-M;
	font-size: 1.28906vw;
	font-weight: 400;
	line-height: 1.28906vw;
	letter-spacing: 0em;
	color: #0078FF;
}
.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-3 {
	position: absolute;
	width: 0.89844vw;
	height: 1.28906vw;
	top: 0.00000vw;
	left: 5.42969vw;
}
.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-3 img{
	width: 100%;
	height: 100%;
}
.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-4 {
	position: absolute;
	width: 3.39844vw;
	height: 0.70313vw;
	top: 1.40625vw;
	left: 6.21094vw;
	font-family: FZLanTingHei-L-GBK-M;
	font-size: 0.70313vw;
	font-weight: 400;
	line-height: 0.70313vw;
	letter-spacing: -0.05em;
	color: rgba(0,0,0,0.6);
}
.d5-body .full-ad.full-ad-1 .ad-body .ad-feature .fp-5 {
	position: absolute;
	width: 10.78125vw;
	height: 1.48438vw;
	top: 2.57813vw;
	left: 0.00000vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.25000vw;
	font-weight: 400;
	line-height: 1.48438vw;
	letter-spacing: -0.05em;
	color: #000000;
}

/*
 * Full-2 :: AD-2
 */
.d5-body .full-ad.full-ad-2 .ad-body-2 {
	position: relative;
	width: 24.25781vw;
	height: 16.99219vw;
	top: 15.46875vw;
	left: 21.36719vw;
}

.d5-body .full-ad.full-ad-2 .ad-body-2 .ad-slogon {
	position: relative;
	min-width: 24.25781vw;
	width: 30vw;
	height: 7.50000vw;
	top: 0.00000vw;
	left: 0.00000vw;
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-2 .ad-body-2 .ad-product{
	position: relative;
	min-width: 9.37500vw;
	width: 18vw;
	height: 3.75000vw;
	margin-top: 2.89063vw;
	left: 0.00000vw;
	top: auto;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.87500vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #0078FF;
}

.d5-body .full-ad.full-ad-2 .ad-body-2 .ad-desc{
	position: relative;
	width: 19.49219vw;
	height: 3.12500vw;
	margin-top: -0.27344vw;
	left: 0.00000vw;
	top: auto;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.01563vw;
	font-weight: 400;
	line-height: 1.56250vw;
	color: rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-2 .ad-body-2 .ad-label{
	position: absolute;
	width: 0.70313vw;
	height: 0.78125vw;
	top: 0.00000vw;
	left: 21.01563vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.62500vw;
	font-weight: 400;
	line-height: 0.78125vw;
	letter-spacing: -0.1em;
	color: #000000;
}
.d5-body .full-ad.full-ad-2 .ad-body-2 .ad-label.ad-label-2 {
    top: 3.55469vw;
    left: 23.9vw;
}

/*
 * Full-2 :: AD-3
 */
.d5-body .full-ad.full-ad-3 .ad-body-3 {
	position: relative;
	width: 23.04688vw;
	height: 27.38281vw;
	top: 10.27344vw;
	left: 55.54688vw;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-slogon {
	position: relative;
	min-width: 22.07031vw;
	width: 30vw;
	height: 7.50000vw;
	top: 0.00000vw;
	left: 0.00000vw;
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-feature {
	position: relative;
	min-width: 22.22656vw;
	width: 30vw;
	height: 3.75000vw;
	margin-top:  2.89063vw;
	left: 0.00000vw;
	top: auto;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.67969vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #0078FF;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-label-1 {
	position:absolute;
	width: 0.31250vw;
	height: 0.62500vw;
	top: 11.32813vw;
	left: 9.57031vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.46875vw;
	font-weight: 400;
	line-height: 0.62500vw;
	letter-spacing: 0em;
	color: #0078FF;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-label-2 {
	position:absolute;
	width: 0.39063vw;
	height: 0.78125vw;
	top: 0.00000vw;
	left: 7.26563vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.62500vw;
	font-weight: 400;
	line-height: 0.78125vw;
	letter-spacing: -0.1em;
	color: #000000;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img {
	position:absolute;
	width: 11.05469vw;
	height: 12.92969vw;
	top: 14.45313vw;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-1 {
	left: 0.00000vw;
	border-radius: 0.74219vw;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-2 {
	left: 11.99219vw;
	border-radius: 0.74219vw;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-1 .ad-product {
	position: relative;
	min-width: 8.00781vw;
	width: 9.00781vw;
	height: 1.25000vw;
	top: 9.33594vw;
	margin-top: 0vw;
	left: 1.52344vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.25000vw;
	font-weight: 400;
	line-height: 1.25000vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-1 .ad-desc {
	position: absolute;
	width: 9.84375vw;
	height: 0.78125vw;
	top: 11.28906vw;
	left: 0.62500vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.78125vw;
	font-weight: 400;
	line-height: 0.78125vw;
	color:rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-1 .ad-label-3 {
	position: absolute;
	width: 0.19531vw;
	height: 0.46875vw;
	top: 11.13281vw;
	left: 3.71094vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.31250vw;
	font-weight: 400;
	line-height: 0.46875vw;
	color:rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-2 .ad-product {
	position: relative;
	min-width: 8.43750vw;
	width: 9.43750vw;
	height: 1.25000vw;
	margin-top: 9.33594vw;
	left: 1.32813vw;
	top:auto;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.05469vw;
	font-weight: 400;
	line-height: 1.25000vw;
	color:rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-3 .ad-body-3 .ad-img-2 .ad-desc {
	position: relative;
	width: 6.48438vw;
	height: 0.78125vw;
	margin-top: 0.70313vw;
	left: 2.30469vw;
	top:auto;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.78125vw;
	font-weight: 400;
	line-height: 0.78125vw;
	color:rgba(0,0,0,0.6);
}

/* Full-2 :: AD-4 */
.d5-body .full-ad.full-ad-4 .ad-body-4 {
	position: relative;
	width: 22.30469vw;
	height: 36.32813vw;
	top: 5.78125vw;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-slogon {
	position: relative;
	min-width: 17.57813vw;
	width: 20vw;
	height: 7.50000vw;
	top: 0.00000vw;
	left: 0.00000vw;
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-product {
	position: relative;
	min-width: 22.30469vw;
	width: 30vw;
	height: 3.75000vw;
	margin-top: 2.89063vw;
	left: 0.00000vw;
	top: auto;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.67969vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color:#0078FF;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img {
	position: relative;
	width: 18.47656vw;
	height: 21.87500vw;
	margin-top: 0.31250vw;
	left: 0.00000vw;
	top: auto;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1,
.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 {
	position: relative;
	border-radius: 0.74219vw;
	width: 18.47656vw;
	height: 10.46875vw;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1 {
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 {
	margin-top: 0.97656vw;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1 .ad-img-txt-1,
.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1 .ad-img-txt-2 {
	position: absolute;
	font-family: FZLanTingHeiS-R-GB;
	font-weight: 400;
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1 .ad-img-txt-1 {
	width: 8.00781vw;
	height: 1.25000vw;
	top: 8.55469vw;
	left: 0.70313vw;
	font-size: 1.25000vw;
	line-height: 1.25000vw;
	color: #000000;
}
.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-1 .ad-img-txt-2 {
	width: 6.25000vw;
	height: 0.78125vw;
	top: 8.90625vw;
	left: 11.56250vw;
	font-size: 0.78125vw;
	line-height: 0.78125vw;
	color:rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 .ad-img-txt-1,
.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 .ad-img-txt-2 {
	position: absolute;
	font-family: FZLanTingHeiS-R-GB;
	font-weight: 400;
	color:rgba(0,0,0,0.6);
}

.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 .ad-img-txt-1 {
	width: 8.43750vw;
	height: 1.25000vw;
	top: 8.43750vw;
	left: 0.70313vw;
	font-size: 1.05469vw;
	line-height: 1.25000vw;
}
.d5-body .full-ad.full-ad-4 .ad-body-4 .ad-img .ad-img-2 .ad-img-txt-2 {
	width: 7.03125vw;
	height: 0.78125vw;
	top: 8.78906vw;
	left: 10.78125vw;
	font-size: 0.78125vw;
	line-height: 0.78125vw;
}

/* Full-2 :: AD-5 */
.d5-body .full-ad.full-ad-5 .ad-body-5 {
	width: 21.79688vw;
	height: 26.52344vw;
	top: 10.23438vw;
	left: 56.32813vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-slogon,
.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-feature {
	min-width: 21.09375vw;
	width: 25vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-1 {
	height: 12.96875vw;
	top: 14.49219vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-1 .ad-product {
	top: 9.29688vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-1 .ad-desc {
	width: 6.48438vw;
	top: 11.25000vw;
	left: 2.30469vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-2 {
	height: 12.96875vw;
	top: 14.49219vw;
	left: 12.03125vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-2 .ad-product {
	margin-top: 9.29688vw;
}

.d5-body .full-ad.full-ad-5 .ad-body-5 .ad-img-2 .ad-desc {
}

/* Half-1 :: Card-Jigsaw AD-1 */
.d5-body .jigsaw-ad {
	position: relative;
	margin-top: 4.17969vw;
	margin-bottom: 4.17969vw;
	margin-left: 21.36719vw;
	width: 57.26563vw;
	height: 72.73438vw;
}

.d5-body .jigsaw-ad .jigsaw-row {
	position: relative;
	width: 57.26563vw;
	min-height: 3.90625vw;
	left: 0.00000vw;
	top: auto;
	margin-bottom: 1.64063vw;
	display: flex;
}

.d5-body .jigsaw-ad .jigsaw-row:last-child {
	margin-bottom: 0;
}

.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-l,
.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-r {
	height: 37.50000vw;
}

.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-l {
	width: 34.06250vw;
}
.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-r {
	width: 21.56250vw;
}

.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-l,
.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-r {
	height: 33.59375vw;
}

.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-l,
.d5-body .jigsaw-ad .jigsaw-row.method-2 .jigsaw-block-r {
	width: 27.81250vw;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l{
	margin-right: 1.64063vw;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l .jigsaw-texts,
.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-r .jigsaw-texts,
.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l .jigsaw-texts-labeled {
	position: relative;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l .jigsaw-texts{
	margin-top:1.99219vw;
	margin-left:2.10938vw;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-r .jigsaw-texts{
	margin-top:1.99219vw;
	margin-left:2.18750vw;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-texts .jigsaw-title {
	font-family: FZLanTingHei-M-GBK;
	font-size: 2.18750vw;
	font-weight: 400;
	line-height: 2.57813vw;
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-texts .jigsaw-desc {
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.56250vw;
	margin-top: 0.58594vw;
	color: rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad .jigsaw-row .jigsaw-block-l .jigsaw-texts-labeled .ad-label {
	position: absolute;
	top: 0.07813vw;
	left: 6.36719vw;
	width: 0.58594vw;
	height: 0.58594vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.50781vw;
	font-weight: 400;
	line-height: 0.58594vw;
}

/* Half-2 :: Card-Jigsaw AD-2 */
.d5-body .jigsaw-ad-2 {
	margin-top: 4.18438vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-1 {
	margin-bottom: 1.60156vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-1 .jigsaw-block-l {
	width: 57.26563vw;
	margin-right: 0;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-1 .jigsaw-block-l .jigsaw-texts .jigsaw-title {
	width: 10.93750vw;
	height: 5.15625vw;
	color: #000000;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-1 .jigsaw-block-l .jigsaw-texts .jigsaw-desc {
	min-width: 6.83594vw;
	width: 10vw;
	height: 1.56250vw;
	margin-top: 0.50781vw;
	color:rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-1 .jigsaw-block-l .jigsaw-texts-labeled .ad-label {
	width: 0.39063vw;
	height: 0.39063vw;
	top: 5.66406vw;
	left: 6.71875vw;
	font-size: 0.35156vw;
	line-height: 0.39063vw;
	letter-spacing: -0.1em;
	color:rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-r .jigsaw-texts .ad-label {
	position: absolute;
	top: 0.07813vw;
	left: 4.41406vw;
	width: 0.31250vw;
	height: 0.58594vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.50781vw;
	font-weight: 400;
	line-height: 0.58594vw;
	letter-spacing: -0.13em;
	color: #000000;
}
.d5-body .jigsaw-ad .jigsaw-row.method-1 .jigsaw-block-r .jigsaw-texts .ad-label-2 {
    position: absolute;
    width: 0.23438vw;
    height: 0.39063vw;
    top: 3.16406vw;
    left: 9.80469vw;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 0.35156vw;
    font-weight: 400;
    line-height: 0.39063vw;
    letter-spacing: 0em;
    color: rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts,
.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts{
	position: relative;
	margin-top: 2.07031vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .jigsaw-title {
	width: 15.27344vw;
	height: 2.57813vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .jigsaw-desc {
	min-width: 9.96094vw;
	width: 12vw;
	height: 1.56250vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .ad-label-1 {
	position: absolute;
	width: 0.31250vw;
	height: 0.58594vw;
	top: 0.07813vw;
	left: 14.96094vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.50781vw;
	font-weight: 400;
	line-height: 0.58594vw;
	letter-spacing: -0.13em;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .ad-label-2 {
	position: absolute;
	width: 0.42969vw;
	height: 0.39063vw;
	top: 3.16406vw;
	left: 6.79688vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 0.35156vw;
	font-weight: 400;
	line-height: 0.39063vw;
	letter-spacing: -0.1em;
	color:rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts .jigsaw-title {
	width: 10.93750vw;
	height: 2.57813vw;
}

.d5-body .jigsaw-ad-2 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts .jigsaw-desc {
	min-width: 6.83594vw;
	width: 10vw;
	height: 1.56250vw;
}

/* Full-3 :: AD-6 */
.d5-body .full-ad.full-ad-6 .ad-body-6 {
	width: 20.19531vw;
	height: 25.85938vw;
	top: 11.01563vw;
	left: 64.14063vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-product {
	position: relative;
	min-width: 19.96094vw;
	width: 25vw;
	height: 3.75000vw;
	top: auto;
	left: 0.234375vw;
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 3.75000vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 {
	position: relative;
	width: 11.67969vw;
	height: 3.16406vw;
	margin-top: 3.82813vw;
	left: 0.23438vw;
	top: auto;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 .ad-mode-icon {
	position:absolute;
	width: 1.91406vw;
	height: 1.48438vw;
	top: 0.11719vw;
	left: 0.00000vw;
}
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 .ad-mode-sub {
	left: 3.24219vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 {
	position: relative;
	width: 10.15625vw;
	height: 3.16406vw;
	margin-top: 1.87500vw;
	left: 0.03906vw;
	top: auto;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 .ad-mode-icon {
	position:absolute;
	width: 2.34375vw;
	height: 1.79688vw;
	top: 0.00000vw;
	left: 0.00000vw;
}
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 .ad-mode-sub {
	left: 3.43750vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 {
	position: relative;
	width: 10.89844vw;
	height: 3.16406vw;
	margin-top: 1.87500vw;
	left: 0.00000vw;
	top: auto;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 .ad-mode-icon {
	position: absolute;
	width: 2.38281vw;
	height: 1.87500vw;
	top: 0.00000vw;
	left: 0.00000vw;
}
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 .ad-mode-sub {
	left: 3.47656vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 {
	position: relative;
	width: 11.87500vw;
	height: 3.20313vw;
	margin-top: 1.83594vw;
	left: 0.03906vw;
	top: auto;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 .ad-mode-icon {
	position: absolute;
	width: 2.30469vw;
	height: 1.79688vw;
	top: 0.00000vw;
	left: 0.00000vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 .ad-mode-sub {
	left: 3.43750vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 .ad-mode-title,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 .ad-mode-title {
	position: relative;
	min-width: 6.71875vw;
	width: 10vw;
	height: 1.67969vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.67969vw;
	font-weight: 400;
	line-height: 1.67969vw;
}

.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-1 .ad-mode-sub,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-2 .ad-mode-sub,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-3 .ad-mode-sub,
.d5-body .full-ad.full-ad-6 .ad-body-6 .ad-mode-4 .ad-mode-sub {
	position: relative;
	width: 8.43750vw;
	height: 1.01563vw;
	margin-top: 0.46875vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.01563vw;
	font-weight: 400;
	line-height: 1.01563vw;
	color:  rgba(0,0,0,0.6);
}

/* Half-3 :: Card-Jigsaw AD-1 */
.d5-body .jigsaw-ad-3 {
	width: 57.26563vw;
	height: 104.53125vw;
	margin-top: 4.18438vw;
	margin-bottom: 4.17969vw;
	margin-left: 21.36719vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l {
	height: 37.50000vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-r {
	height: 37.50000vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-1 .jigsaw-block-w {
	width: 57.26563vw;
	height: 31.87500vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts,
.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts {
	position: relative;
	margin-left: 2.18750vw;
	margin-top: 1.99219vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-3 .jigsaw-block-w .jigsaw-texts,
.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-4 .jigsaw-block-w .jigsaw-texts {
	position: relative;
	margin-left: 2.10938vw;
	margin-top: 1.99219vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .jigsaw-title {
	width: 12.07031vw;
	height: 2.57813vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 2.18750vw;
	font-weight: 400;
	line-height: 2.57813vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .jigsaw-desc {
	margin-top: 0.58594vw;
	min-width: 15.42969vw;
	width: 20vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.56250vw;
	color: rgba(0,0,0,0.6);
}
.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-l .jigsaw-texts .ad-label {
    position: absolute;
    width: 0.3125vw;
    height: 0.58594vw;
    top: 0.11719vw;
    left: 12.07031vw;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 0.50781vw;
    font-weight: 400;
    line-height: 0.58594vw;
    letter-spacing: -0.13em;
    color: #000000;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts .jigsaw-title {
	width: 13.12500vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 2.18750vw;
	font-weight: 400;
	line-height: 2.57813vw;
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-2 .jigsaw-block-r .jigsaw-texts .jigsaw-desc {
	min-width: 12.30469vw;
	width: 15vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.56250vw;
	color: rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-3 .jigsaw-block-w .jigsaw-texts .jigsaw-title {
	width: 10.93750vw;
	height: 2.57813vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 2.18750vw;
	font-weight: 400;
	line-height: 2.57813vw;
}
.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-3 .jigsaw-block-w .jigsaw-texts .jigsaw-desc {
	margin-top: 0.58594vw;
	min-width: 12.30469vw;
	width: 15vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.56250vw;
	color: rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-4 .jigsaw-block-w .jigsaw-texts .jigsaw-title {
	width: 12.03125vw;
	height: 2.57813vw;
	font-family: FZLanTingHei-M-GBK;
	font-size: 2.18750vw;
	font-weight: 400;
	line-height: 2.57813vw;
}
.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-4 .jigsaw-block-w .jigsaw-texts .jigsaw-desc {
	margin-top: 0.58594vw;
	min-width: 15.03906vw;
	width: 20vw;
	font-family: FZLanTingHeiS-R-GB;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.56250vw;
	color: rgba(0,0,0,0.6);
}

.d5-body .jigsaw-ad-3 .jigsaw-row.method-1-4 .jigsaw-block-w .jigsaw-texts .ad-label {
    position: absolute;
    width: 0.3125vw;
    height: 0.58594vw;
    top: 0vw;
    left: 11.91406vw;
    font-family: FZLanTingHeiS-R-GB;
    font-size: 0.50781vw;
    font-weight: 400;
    line-height: 0.58594vw;
    letter-spacing: -0.13em;
    color: #000000;
}

/* ******************************TODO start ******************************** */

/* Full-4 :: AD-7 */
.d5-body .full-ad.full-ad-7 {
	position: relative;
	width: 100.00000vw;
	height: 44.25781vw;
}


.d5-body .full-ad.full-ad-7 .ad-body-2 {
	position: relative;
	width: 14.06250vw;
	height: 4.14063vw;
	top: 16.75781vw;
	left: 58.32031vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-product {
	font-family: FZLanTingHeiS-DB-GB;
	font-size: 3.51563vw;
	font-weight: 400;
	line-height: 4.14063vw;
	color: #000000;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features {
	position: relative;
	width: 20.78125vw; /* set to the max width of 4 items */
	height: 1.60156vw;
	top: 3.59375vw;
	left: 0.07813vw;
	border: 0.03906vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature-text {
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.36719vw;
	font-weight: 400;
	line-height: 1.60156vw;
	padding-left: 1.83594vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature {
	position: relative;
	margin-bottom: 1.09375vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature-text .ad-feature-sub-text1,
.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature-text .ad-feature-sub-text2 {
	font-family: FZLanTingHei-M-GBK;
	font-size: 0.82031vw;
	font-weight: 400;
	line-height: 0.97656vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature .ad-label {
	position: absolute;
	width: 1.48438vw;
	height: 1.48438vw;
	top: 0.07813vw;
	left: 0.00000vw;
	border: 0.03906vw solid transparent;
	font-family: FZLanTingHei-M-GBK;
	font-size: 1.01563vw;
	font-weight: 400;
	line-height: 1.48438vw;
	text-align: center;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-features .ad-feature .ad-circle {
	position: absolute;
	width: 1.48438vw;
	height: 1.48438vw;
	top: 0.07813vw;
	left: 0.00000vw;
	border: 0.03906vw solid #000000;
	border-radius: 0.78125vw;
}

.d5-body .full-ad.full-ad-7 .ad-body-2 .ad-special-desc {
    position: absolute;
    width: 10.46875vw;
    height: 1.25vw;
    top: 19.10156vw;
    left: 1.64063vw;
    font-family: FZLanTingHei-M-GBK;
    font-size: 0.54688vw;
    font-weight: 400;
    line-height: 0.625vw;
    letter-spacing: 0em;
    color: rgba(0,0,0,0.4);
}

/* Half-4 :: Text-Jigsaw AD-1 */
.d5-body .jigsaw-ad-4.jigsaw-desc {
	font-family: FZLanTingHei-L-GBK-M;
	font-size: 0.70313vw;
	font-weight: 400;
	line-height: 1.36719vw;
	letter-spacing: 0em;
	text-align: left;
	height: 15.03906vw;
	color: rgba(0,0,0,0.5);
	margin-top: 5.97656vw;
	margin-bottom: 6.01563vw;
}

.d5-body .jigsaw-ad-4.jigsaw-desc p {
	margin: 0.00000vw;
}

</style>
