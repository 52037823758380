<template>
    <div class="header">
        <div class="nav">
            <div class="logo-box clickable" @click="$router.push('/home')"></div>
            <div class="nav-items">
                <div class="nav-item clickable">
                    <span>商城</span>
                    <div class="float-menu-nav float-menu-nav-plus float-menu-g0" data-cls-bak="float-menu-nav float-menu-nav-plus float-menu-g0" data-popup-id="g0">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g0-img1 clickable" @click="openLink('https://soocare.tmall.com/', 'tmall')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://soocare.tmall.com/', 'tmall')">天猫旗舰店</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g0-img2 clickable" @click="openLink('https://mall.jd.com/index-1000086237.html', 'jd')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://mall.jd.com/index-1000086237.html', 'jd')">京东旗舰店</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g0-img3 clickable" @click="openLink('https://www.douyin.com/user/MS4wLjABAAAAbqRtKtltr7yRAPcyPESy21s878t18IxQwUBhSJDEz7hro2kL2Azw1nRsgBrSaBiz', 'douyin')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://www.douyin.com/user/MS4wLjABAAAAbqRtKtltr7yRAPcyPESy21s878t18IxQwUBhSJDEz7hro2kL2Azw1nRsgBrSaBiz', 'douyin')">抖音旗舰店</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-item clickable">
                    <span><a href="#/brush">电动牙刷</a></span>
                    <div class="float-menu-nav float-menu-nav-pro float-menu-g1" data-cls-bak="float-menu-nav float-menu-nav-pro float-menu-g1" data-popup-id="g1">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g1-img1 clickable" @click="$router.push('/d5')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/d5')">D5 云感刷</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g1-img2 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665853381051', 'd3s')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665853381051', 'd3s')">D3S 猫爪刷</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g1-img3 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=544747042379', 'x3s')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=544747042379', 'x3s')">X3S 大白刷</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-item clickable">
                    <span><a href="#/waterfloss">冲牙器</a></span>
                    <div class="float-menu-nav float-menu-nav-pro float-menu-g2" data-cls-bak="float-menu-nav float-menu-nav-pro float-menu-g2" data-popup-id="g2">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g2-img1 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=597419089214', 'w3p')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=597419089214', 'w3p')">W3P 冲牙器</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g2-img2 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=623614187391', 'w1')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=623614187391', 'w1')">W1 冲牙器</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-item clickable">
                    <span><a href="#/accessory">刷头配件</a></span>
                    <div class="float-menu-nav float-menu-g3" data-cls-bak="float-menu-nav float-menu-g3" data-popup-id="g3">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g3-img1 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=538259798447&skuId=5067564280858', 'cloud')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=538259798447&skuId=5067564280858', 'cloud')">云感刷头</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g3-img2 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665854557738&skuId=5071707865635', 'cloud-brightly')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665854557738&skuId=5071707865635', 'cloud-brightly')">云感亮白刷头</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g3-img3 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=633448087389&skuId=4521317129485', 'w1-spray-nozzle')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=633448087389&skuId=4521317129485', 'w1-spray-nozzle')">W1喷嘴</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g3-img4 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=632472076722', 'w3p-spray-nozzle')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=632472076722', 'w3p-spray-nozzle')">W3P喷嘴</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g3-img5 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=650268621790', 'wall-sticker')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=650268621790', 'wall-sticker')">壁挂墙贴</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g3-img6 clickable" @click="openLink('https://detail.tmall.com/item.htm?id=555431259993&skuId=5001551882817', 'picker')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://detail.tmall.com/item.htm?id=555431259993&skuId=5001551882817', 'picker')">牙线棒50支</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-item clickable">
                    <span><a href="#/postsale">服务与支持</a></span>
                    <div class="float-menu-nav float-menu-nav-plus float-menu-g4" data-cls-bak="float-menu-nav float-menu-nav-plus float-menu-g4" data-popup-id="g4">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g4-img1 clickable" @click="$router.push('/postsale?goto=postsale')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/postsale?goto=postsale')">售后服务</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g4-img2 clickable" @click="$router.push('/postsale?goto=security')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/postsale?goto=security')">防伪验证</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g4-img3 clickable" @click="$router.push('/postsale?goto=dealer')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/postsale?goto=dealer')">官方授权店验证</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g4-img4 clickable" @click="$router.push('/agreement')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/agreement')">服务协议</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav-item clickable">
                    <span><a href="#/about">关于素士</a></span>
                    <div class="float-menu-nav float-menu-nav-plus float-menu-g5" data-cls-bak="float-menu-nav float-menu-nav-plus float-menu-g5" data-popup-id="g5">
                        <div class="float-menu-group">
                            <div class="group-item">
                                <div class="group-item-img g5-img1 clickable" @click="$router.push('/about')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/about')">公司简介</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g5-img2 clickable" @click="$router.push('/subscribe')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/subscribe')">关注我们</div>
                            </div>
                            <div class="group-item"><!-- https://app.mokahr.com/edit-recruitment?type=social&orgId=soocas&siteId=95226&hireMode=1 -->
                                <div class="group-item-img g5-img3 clickable" @click="openLink('https://zhaopin.soocas.com', 'moka')"></div>
                                <div class="group-item-txt clickable" @click="openLink('https://zhaopin.soocas.com', 'moka')">加入我们</div>
                            </div>
                            <div class="group-item">
                                <div class="group-item-img g5-img4 clickable" @click="$router.push('/contact')"></div>
                                <div class="group-item-txt clickable" @click="$router.push('/contact')">联系我们</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- assistant blocks -->
            <div class="before-block"></div>
            <div class="after-block"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: function () {
    return {}
  },
  mounted() {
    this.configGlobalHidePopoup();
    this.configEmitPopup();
  },
  methods: {
    openLink(url, name) {
        window.open(url, name);
    },
    hidePopup() {
        let popups = document.querySelectorAll(".float-menu-nav.popup");
        popups.forEach((p) => {
            p.className = p.getAttribute("data-cls-bak");
        });
    },
    showNavPopup(popupId) {
        let popups = document.querySelectorAll(".float-menu-nav");
        popups.forEach((p) => {
            if(p.getAttribute("data-popup-id") != popupId) {
                p.className = p.getAttribute("data-cls-bak");
            } else {
                p.className = p.getAttribute("data-cls-bak") + " popup";
            }
        });
    },
    configGlobalHidePopoup() {
        let _this = this;

        let nav = document.querySelector(".nav");
        let b1 = document.querySelector(".before-block");
        let b2 = document.querySelector(".after-block");

        nav.addEventListener("mouseleave", function(){
            _this.hidePopup();
        });
        b1.addEventListener("mouseenter", function(){
            _this.hidePopup();
        });
        b2.addEventListener("mouseenter", function(){
            _this.hidePopup();
        });
    },
    configEmitPopup() {
        let _this = this;
        let navs = document.querySelectorAll(".nav-item");
        navs.forEach((nav) => {
            let myPopup = nav.querySelector(".float-menu-nav");
            let popupId = myPopup.getAttribute("data-popup-id");

            nav.addEventListener("mouseenter", function(){
                _this.showNavPopup(popupId);
            });
        });
    }
  }
}
</script>

<style lang="less" scoped>
.header {
    .nav {
        .before-block {
            position: absolute;
            left:0vw;
            top:0vw;
            width:21.36718vw;
            height:2.61718vw;
        }
        .after-block {
            position: absolute;
            left: 51.5625vw;
            top: 0vw;
            width: 48.4374vw;
            height:2.61718vw;
        }
    }
}
</style>