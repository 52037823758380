<template>
    <div class="body">
        <!-- full-width AD -->
        <div id="home_bg1" class="full-ad full-ad-1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body">
                <div class="ad-slogon">告别硬震猛<br>护牙不伤龈</div>
                <div class="ad-digital-label">1</div>
                <div class="ad-product">D5 云感刷</div>
                <div class="ad-button" @click="$router.push('/d5')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div id="home_bg2" class="full-ad full-ad-2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-slogon">护牙有专利<br>净齿治愈系</div>
                <div class="ad-digital-label">2</div>
                <div class="ad-product">D3S 情侣猫爪刷</div>
                <div class="ad-button" @click="openLink('https://detail.tmall.com/item.htm?id=665853381051', 'd3s')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>

        <!-- half-width AD -->
        <div class="half-width-ad half-ad-1">
            <div class="ad-box left">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">告别牙磨损 亮白不伤牙</div>
                    <div class="ad-digital-label">1</div>
                    <div class="ad-b-product">X3S 经典大白刷</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://detail.tmall.com/item.htm?id=544747042379', 'x3s')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img1">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/home/04-x3s.png" />
                </div>
            </div>
            <div class="ad-box right">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">新手入门 护龈不易出血</div>
                    <div class="ad-b-product">W3P 专业便携冲牙器</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://detail.tmall.com/item.htm?id=597419089214', 'w3p')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img2">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/home/05-w3p.png" />
                </div>
            </div>
        </div>
        <div class="half-width-ad half-ad-2">
            <div class="ad-box left">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">专利稳压护龈&nbsp;&nbsp;冲牙=香氛SPA</div>
                    <div class="ad-digital-label">3</div>
                    <div class="ad-b-product">W1 香氛便携冲牙器</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://detail.tmall.com/item.htm?id=623614187391', 'w1')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img3">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/home/06-w1.png" />
                </div>
            </div>
            <div class="ad-box right">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">云感舒适净齿 呵护不伤牙</div>
                    <div class="ad-digital-label">1</div>
                    <div class="ad-b-product">云感系列刷头</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://soocare.tmall.com/category-1464517386.htm?parentCatId=1264078061', 'cloud')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img4">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/home/07.png" />
                </div>
            </div>
        </div>

        <!-- VID -->
        <div class="video-ad">
            <div class="vid-box">
                <video id="vid001" preload="">
                    <source src="https://file.soocas.com/sg9_resource/soocas-site/home/08-lab-v.mp4" type="video/mp4">
                </video>
            </div>
            <div class="vid-ctrls vid-preview-first vid-preview">
                <div class="vid-play-btn"></div> <!-- vid-pause-btn -->
            </div>
        </div>

        <!-- More -->
        <div class="more">
            <div class="more-title">
                <span>更多信息</span>
            </div>
            <div class="more-ads">
                <div class="more-item left more-text-box more-box-1">
                    <div class="more-text">
                        <div class="more-text-title">售后服务</div>
                        <div class="more-text-button clickable" @click="$router.push('/postsale?goto=postsale')">了解更多</div>
                    </div>
                </div>
                <div class="more-item right">
                    <div class="more-sub-item more-text-box more-box-2">
                        <div class="more-text">
                            <div class="more-text-title">联系我们</div>
                            <div class="more-text-button clickable" @click="$router.push('/contact')">了解更多</div>
                        </div>
                    </div>
                    <div class="more-sub-item item-2 more-text-box more-box-3">
                        <div class="more-text">
                            <div class="more-text-title">关于素士</div>
                            <div class="more-text-button clickable" @click="$router.push('/about')">了解更多</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="desc">
            <div class="desc-body">
                注释<br>
                1.数据来源于素士实验室,指39名受试者使用素士云感刷头后自我评估数据,仅供参考,实际效果因人而异。<br>
                2.实用新型专利号:ZL201720473306.8。<br>
                3.实用新型专利号:ZL201810722634.6。
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true
        };
    },
    mounted() {
        this.doVidInit();
        this.doLazyBinding();
    },
    methods: {
        openLink(url, name) {
            window.open(url, name);
        },
        doVidInit() {
            let query = function(selector){
                return document.querySelector(selector);
            };

            let bind = function(el, etype, callback) {
                el.addEventListener(etype, callback, false);
            };

            let setInitCtrls = function(ctrls) {
                ctrls.className = "vid-ctrls vid-preview-first vid-preview";
            };
            let setPreviewOn = function(ctrls) {
                ctrls.className = "vid-ctrls vid-preview";
            };
            let setPreviewOff = function(ctrls) {
                ctrls.className = "vid-ctrls";
            };

            var v = query("#vid001");
            var play = query(".vid-play-btn");
            var ctrls = query(".vid-ctrls");

            bind(play, "click", function(e){
                e.stopPropagation();
                if(v.paused) {
                    setPreviewOff(ctrls);
                    v.play();
                }
            });

            bind(ctrls, "click", function(){
                if(!v.paused)  {
                    setPreviewOn(ctrls);
                    v.pause();
                }
            });

            bind(v, "ended", function(){
                setInitCtrls(ctrls);
                v.pause();
            });
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
            };
            _this.checkBg1();
            _this.checkBg2();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#home_bg1")) {
                    this.beforeBg1 = false;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#home_bg2")) {
                    this.beforeBg2 = false;
                }
            }
        } // end
    }
}
</script>

<style lang="less" scoped>
.full-ad-1 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/home/01-d5.jpg');
}
.full-ad-2 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/home/02-d3s.jpg');
}

.body .bg-loading {
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-transparent.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

/*
.border-1_0px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.03906vw;}

.border-1_5px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.058594vw;}
*/

.border-2_0px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.078125vw;}


.body {

    .half-width-ad {
        .ad-box {
            .ad-b-desc {
                .ad-b-button {
                    /*
                    span {
                        .left-4 {height: 1.356vw;}
                    }
                    */
                    span.adbb {
                        height: 2.22657vw;
                    }
                }
            }
        }
    }

    .full-ad{
        .ad-body {
            .ad-digital-label {
                position: absolute;
                width: 0.3125vw;
                height: 0.78125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.46875vw;
                font-weight: 400;
                line-height: 0.78125vw;
                letter-spacing: -0.13em;
                color: #000000;
            }
        }
    }

    .full-ad.full-ad-1 {
        .ad-body {
            top:16.32813vw;
            left:21.36719vw;
            .ad-digital-label {
                top: 2.69531vw;
                left: 12.5vw;
            }
        }
    }

    .full-ad.full-ad-2 {
        .ad-body {
            top:16.32813vw;
            left:21.36719vw;
            .ad-digital-label {
                top: 0vw;
                left: 12.5vw;
            }
        }
    }

    .half-width-ad{
        .ad-box.left, .ad-box.right {
            .ad-b-desc{
                .ad-digital-label {
                    position: absolute;
                    width: 0.27344vw;
                    height: 0.78125vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.39063vw;
                    font-weight: 400;
                    line-height: 0.78125vw;
                    letter-spacing: -0.13em;
                    color: #000000;
                }
            }
        }
    }

    .half-width-ad.half-ad-1{
        .ad-box.left{
            .ad-b-desc{
                .ad-digital-label{
                    top: 0.11719vw;
                    left: 34.41406vw;
                }
            }
        }
    }

    .half-width-ad.half-ad-2{
        .ad-box.left{
            .ad-b-desc{
                .ad-digital-label {
                    top: 0.07813vw;
                    left: 22.1875vw;
                }
            }
        }
        .ad-box.right{
            .ad-b-desc{
                .ad-digital-label {
                    top: 0.11719vw;
                    left: 35.46875vw;
                }
            }
        }
    }

}
</style>
