 const  lessIE10  = function () {
    let status = false ;
    //取得浏览器的userAgent字符串
    let userAgent = navigator.userAgent;
    //判断是否IE浏览器
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion < 10) {
            status =  true;
        }
    }
    return status;
}

 export const isLessIE10 = lessIE10();
