import { render, staticRenderFns } from "./D5.vue?vue&type=template&id=6296a83c&scoped=true"
import script from "./D5.vue?vue&type=script&lang=js"
export * from "./D5.vue?vue&type=script&lang=js"
import style0 from "./D5.vue?vue&type=style&index=0&id=6296a83c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6296a83c",
  null
  
)

export default component.exports