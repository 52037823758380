export default {
    state: {
        themeId:{}
    },
    getters: {
        getThemeId: state => state.themeId
    },
    mutations: {
        setThemeId(state, themeId) {
            state.themeId = themeId
        }
    }
}