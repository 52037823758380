<template>
    <div class="body contact-box">
        <div class="cb-title">联系我们</div>
        <div class="cb-content">
            <div class="cb-line cb-line-1"></div>
            <div class="cb-line cb-line-2"></div>
            <div class="cb-line cb-line-3"></div>

            <div class="cb-block cb-block-1">
                <div class="cb-block-title">国内业务</div>
                <div class="cb-block-pair cb-block-pair1">
                    <div class="cb-pair-s1">企业内购、线上分销业务、线下经销商合作</div>
                    <div class="cb-pair-s2">邮箱：sales@soocas.com</div>
                </div>
                <div class="cb-block-pair cb-block-pair2">
                    <div class="cb-pair-s1">品牌合作</div>
                    <div class="cb-pair-s2">邮箱：cooperation@soocas.com</div>
                </div>
                <div class="cb-block-pair cb-block-pair3">
                    <div class="cb-pair-s1">投资者关系</div>
                    <div class="cb-pair-s2">邮箱：ir@soocas.com</div>
                </div>
            </div>
            <div class="cb-block cb-block-2">
                <div class="cb-block-title">海外业务</div>
                <div class="cb-block-pair cb-block-pair1">
                    <div class="cb-pair-s1">线下经销商合作</div>
                    <div class="cb-pair-s2">邮箱：wholesale@soocas.com</div>
                </div>
                <div class="cb-block-pair cb-block-pair2">
                    <div class="cb-pair-s1">品牌合作</div>
                    <div class="cb-pair-s2">邮箱：info@soocas.com</div>
                </div>
                <div class="cb-block-pair cb-block-pair3">
                    <div class="cb-pair-s1">媒介合作</div>
                    <div class="cb-pair-s2">邮箱：hello@soocas.com</div>
                </div>
                <div class="cb-block-pair cb-block-pair4">
                    <div class="cb-pair-s1">售后支持</div>
                    <div class="cb-pair-s2">邮箱：Support@soocas.com</div>
                </div>
            </div>
            <div class="cb-block cb-block-3">
                <div class="cb-block-title">其他业务</div>
                <div class="cb-block-pair cb-block-pair1">
                    <div class="cb-pair-s1">客服热线</div>
                    <div class="cb-pair-s2 cb-pair-s2-plus">电话：400-788-4008<br>周一至周五 9:00AM-18:00PM</div>
                </div>
                <div class="cb-block-pair cb-block-pair2">
                    <div class="cb-pair-s1">诚信廉洁举报邮箱</div>
                    <div class="cb-pair-s2">邮箱：fwbjb@soocas.com</div>
                </div>
            </div>
            <div class="cb-block cb-block-4">
                <div class="cb-block-title">联系地址</div>
                <div class="cb-block-pair cb-block-pair1">
                    <div class="cb-pair-s1">公司地址</div>
                    <div class="cb-pair-s2 cb-pair-s2-plus">深圳市南山区桃源街道福光社区留仙大道3370号<br>南山智园崇文园区2号楼11楼 深圳素士科技股份有限公司</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {}
}
</script>

<style lang="less" scoped>
.contact-box {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    height: 83.08594vw;

    .cb-title {
        position: absolute;
        width: 14.0625vw;
        height: 4.10156vw;
        top: 5.97656vw;
        left: 42.96875vw;
        font-family: FZLanTingHei-M-GBK;
        font-size: 3.51563vw;
        font-weight: 400;
        line-height: 4.10156vw;
        letter-spacing: 0em;
        color: #000000;
    }

    .cb-content {
        position: absolute;
        width: 57.26563vw;
        height: 58.47656vw;
        top: 16.01563vw;
        left: 21.36719vw;

        .cb-line {
            position: absolute;
            width: 57.26563vw;
            height: 0.03906vw;
            left: 0vw;
            background-color: rgba(0,0,0,0.4);
        }
        .cb-block {
            position: absolute;
            width: 57.26563vw;
            left: 0vw;

            .cb-block-title {
                position:absolute;
                height: 2.1875vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 1.875vw;
                font-weight: 400;
                line-height: 2.19727vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .cb-block-pair {
                position:absolute;

                .cb-pair-s1 {
                    position:absolute;
                    height: 1.05469vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.9375vw;
                    font-weight: 400;
                    line-height: 1.05469vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
                .cb-pair-s2 {
                    position:absolute;
                    height: 1.05469vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.9375vw;
                    font-weight: 400;
                    line-height: 1.07305vw;
                    letter-spacing: 0em;
                    color: rgba(0,0,0,0.5);
                }
                .cb-pair-s2-plus {
                    position:absolute;
                    height: 2.65625vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.9375vw;
                    font-weight: 400;
                    line-height: 1.32813vw;
                    letter-spacing: 0em;
                }
            }
        }

        .cb-line.cb-line-1 {
            top: 13.63281vw;
        }
        .cb-line.cb-line-2 {
            top: 31.32813vw;
        }
        .cb-line.cb-line-3 {
            top: 46.91406vw;
        }

        .cb-block.cb-block-1 {
            height: 9.60938vw;
            top: 0vw;

            .cb-block-title {
                width: 7.5vw;
                top: 0vw;
                left: 0vw;
            }
            .cb-block-pair.cb-block-pair1 {
                width: 17.8125vw;
                height: 2.38281vw;
                top: 3.51563vw;
                left: 0vw;
                .cb-pair-s1 {
                    width: 17.8125vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 11.79688vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair2 {
                width: 14.72656vw;
                height: 2.38281vw;
                top: 3.51563vw;
                left: 42.53906vw;
                .cb-pair-s1 {
                    width: 3.75vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 14.72656vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair3 {
                width: 10.19531vw;
                height: 2.38281vw;
                top: 7.22656vw;
                left: 0vw;
                .cb-pair-s1 {
                    width: 4.96094vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 10.19531vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
        }
        .cb-block.cb-block-2 {
            height: 9.60938vw;
            top: 17.69531vw;

            .cb-block-title {
                width: 7.5vw;
                top: 0vw;
                left: 0vw;
            }
            .cb-block-pair.cb-block-pair1 {
                width: 14.17969vw;
                height: 2.38281vw;
                top: 3.51563vw;
                left: 0vw;

                .cb-pair-s1 {
                    width: 6.5625vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 14.17969vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair2 {
                width: 11.01563vw;
                height: 2.38281vw;
                top: 3.51563vw;
                left: 44.29688vw;

                .cb-pair-s1 {
                    width: 3.75vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 11.01563vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair3 {
                width: 11.75781vw;
                height: 2.38281vw;
                top: 7.22656vw;
                left: 0vw;

                .cb-pair-s1 {
                    width: 4.02344vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 11.75781vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair4 {
                width: 12.96875vw;
                height: 2.38281vw;
                top: 7.22656vw;
                left: 44.29688vw;

                .cb-pair-s1 {
                    width: 4.02344vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 12.96875vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
        }
        .cb-block.cb-block-3 {
            height: 7.5vw;
            top: 35.39063vw;

            .cb-block-title {
                width: 8.00781vw;
                top: 0vw;
                left: 0vw;
            }
            .cb-block-pair.cb-block-pair1 {
                width: 13.47656vw;
                height: 3.98438vw;
                top: 3.51563vw;
                left: 0vw;

                .cb-pair-s1 {
                    width: 4.02344vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2.cb-pair-s2-plus {
                    width: 13.47656vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
            .cb-block-pair.cb-block-pair2 {
                width: 11.75781vw;
                height: 2.38281vw;
                top: 3.51563vw;
                left: 45.50781vw;

                .cb-pair-s1 {
                    width: 7.77344vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2 {
                    width: 11.75781vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
        }
        .cb-block.cb-block-4 {
            width: 23.78906vw;
            height: 7.5vw;
            top: 50.97656vw;

            .cb-block-title {
                width: 8.00781vw;
                top: 0vw;
                left: 0vw;
            }
            .cb-block-pair.cb-block-pair1 {
                width: 23.78906vw;
                height: 3.98438vw;
                top: 3.51563vw;
                left: 0vw;

                .cb-pair-s1 {
                    width: 4.25781vw;
                    top: 0vw;
                    left: 0vw;
                }
                .cb-pair-s2.cb-pair-s2-plus {
                    width: 23.78906vw;
                    top: 1.32813vw;
                    left: 0vw;
                }
            }
        }

    }
}
</style>