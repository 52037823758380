<template>
    <div class="body about-box">
        <!-- fa-1 -->
        <div id="about_bg1" class="full-ad about-fa-1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body">
                <div class="ad-title-1">更适合国人亚健康口腔</div>
                <div class="ad-title-2">专业口腔护理品牌</div>
            </div>
        </div>

        <!-- fa-2 -->
        <div id="about_bg2" class="full-ad about-fa-2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-group-1">
                    <div class="ad-g1-s1">口腔医学博士建议<br>牙齿敏感<br>用专业护牙牙刷</div>
                    <div class="ad-g1-s2">焦九阳</div>
                    <div class="ad-g1-s3">口腔临床医学博士</div>
                    <div class="ad-g1-bracket">
                        <div class="ad-bracket-1"></div>
                        <div class="ad-bracket-2"></div>
                    </div>
                </div>
                <div class="ad-group-2">
                    <div class="ad-g2-s1">87.4%</div>
                    <div class="ad-g2-s2">1</div>
                    <div class="ad-g2-s3">牙龈出血检出率</div>
                </div>
                <div class="ad-group-3">
                    <div class="ad-g3-s1">1.5</div>
                    <div class="ad-g3-s2">2</div>
                    <div class="ad-g3-s3">颗牙</div>
                    <div class="ad-g3-s4">人均牙敏感颗数</div>
                </div>
            </div>
        </div>

        <!-- fa-3 -->
        <div id="about_bg3" class="full-ad about-fa-3" :class="{'bg-loading':beforeBg3}">
            <div class="ad-body">
                <div class="ad-fa3-s1">品牌愿景</div>
                <div class="ad-fa3-s2"></div>
                <div class="ad-fa3-s3">专研国人亚健康口腔</div>
                <div class="ad-fa3-s4">成为国人<br>口腔护理首选品牌</div>
                <div class="ad-fa3-s5">品牌使命</div>
                <div class="ad-fa3-s6"></div>
                <div class="ad-fa3-s7">提供适宜国人口腔特质的解决方案</div>
                <div class="ad-fa3-s8">提升全民<br>口腔健康水平</div>
            </div>
        </div>

        <!-- fa-4 -->
        <div id="about_bg4" class="full-ad about-fa-4" :class="{'bg-loading':beforeBg4}">
            <div class="ad-body">
                <div class="ad-fa4-box-1">
                    <div class="ad-fa4-b1-s1">专注敏感口腔研发</div>
                    <div class="ad-fa4-b1-s2">国家CNAS认可独立实验室</div>
                    <div class="ad-fa4-b1-s3">3</div>
                </div>
                <div class="ad-fa4-box-2">
                    <div class="ad-fa4-b2-g1">
                        <div class="ad-fa4-b2g1-up">850</div>
                        <div class="ad-fa4-b2g1-dn">平方米</div>
                    </div>
                    <div class="ad-fa4-b2-g2">
                        <div class="ad-fa4-b2g2-up">18</div>
                        <div class="ad-fa4-b2g2-dn">个独立测试设备</div>
                    </div>
                    <div class="ad-fa4-b2-g3">
                        <div class="ad-fa4-b2g3-up">200</div>
                        <div class="ad-fa4-b2g3-dn">余套检测设备</div>
                    </div>
                    <div class="ad-fa4-b2-g4">
                        <div class="ad-fa4-b2g4-up">产品标准</div>
                        <div class="ad-fa4-b2g4-dn">检测中心</div>
                    </div>
                    <div class="ad-fa4-b2-g5">
                        <div class="ad-fa4-b2g5-up">用户体验</div>
                        <div class="ad-fa4-b2g5-dn">研究中心</div>
                    </div>
                    <div class="ad-fa4-b2-l1"></div>
                    <div class="ad-fa4-b2-l2"></div>
                    <div class="ad-fa4-b2-l3"></div>
                    <div class="ad-fa4-b2-l4"></div>
                </div>
            </div>
        </div>

        <!-- fa-5 -->
        <div class="full-ad about-fa-5">
            <div class="ad-bg">
                <video autoplay="autoplay" muted="muted" loop="loop" preload="">
                    <source src="https://file.soocas.com/sg9_resource/soocas-site/about/05-HairGrafting.mp4" type="video/mp4">
                </video>
            </div>
            <div class="ad-body">
                <div class="ad-fa5-slogan">坚持自主创新 用科技引领升级</div>
                <div class="ad-fa5-desc">
                    <div class="ad-fa5-desc-left">
                        <div class="ad-fa5-left-digit">703+</div>
                        <div class="ad-fa5-left-suffix">项申请专利</div>
                    </div>
                    <div class="ad-fa5-desc-right">
                        <div class="ad-fa5-right-digit">584+</div>
                        <div class="ad-fa5-right-suffix">项授权专利</div>
                    </div>
                    <div class="ad-fa5-desc-splitter"></div>
                </div>
            </div>
        </div>

        <!-- fa-6 -->
        <div id="about_bg5" class="full-ad about-fa-6" :class="{'bg-loading':beforeBg5}">
            <div class="ad-body">
                <div class="ad-fa6-basis-1">定义标准化</div>
                <div class="ad-fa6-basis-2">让好产品有标准</div>
            </div>
        </div>

        <!-- fa-7 -->
        <div id="about_bg6" class="full-ad about-fa-7" :class="{'bg-loading':beforeBg6}">
            <div class="ad-body">
                <div class="ad-fa7-s1">以己之力 赋能口腔护理行业</div>
                <div class="ad-fa7-box">
                    <div class="ad-fa7-left">
                        <div class="ad-fa7-left-s1">团体标准起草者</div>
                        <div class="ad-fa7-left-s1-label">5</div>
                        <div class="ad-fa7-left-s2">电动牙刷和电动冲牙器</div>
                    </div>
                    <div class="ad-fa7-right">
                        <div class="ad-fa7-right-s1">副主席委员单位</div>
                        <div class="ad-fa7-right-s2">中国家用电器协会 美健（个护）电器专委会</div>
                    </div>
                    <div class="ad-fa7-splitter"></div>
                </div>
            </div>
        </div>

        <!-- fa-8 -->
        <div class="full-ad about-fa-8">
            <div class="ad-body">
                <div class="ad-fa8-title">
                    <div class="ad-title-s1">品牌历程</div>
                    <div class="ad-title-s2">坚定初心  做对用户有独特价值的事</div>
                </div>
                <div class="ad-fa8-years">
                    <div class="ad-fa-year y2023 highlight" @mouseenter="chooseYear">2023</div>
                    <div class="ad-fa-year y2022" @mouseenter="chooseYear">2022</div>
                    <div class="ad-fa-year y2021" @mouseenter="chooseYear">2021</div>
                    <div class="ad-fa-year y2020" @mouseenter="chooseYear">2020</div>
                    <div class="ad-fa-year y2019" @mouseenter="chooseYear">2019</div>
                    <div class="ad-fa-year y2018" @mouseenter="chooseYear">2018</div>
                    <div class="ad-fa-year y2017" @mouseenter="chooseYear">2017</div>
                    <div class="ad-fa-year y2016" @mouseenter="chooseYear">2016</div>
                </div>
                <div class="ad-fa8-axis"></div>
                <div class="ad-fa8-ellipse">
                    <div class="ad-fa8-ellipse y2023 highlight"></div>
                    <div class="ad-fa8-ellipse y2022"></div>
                    <div class="ad-fa8-ellipse y2021"></div>
                    <div class="ad-fa8-ellipse y2020"></div>
                    <div class="ad-fa8-ellipse y2019"></div>
                    <div class="ad-fa8-ellipse y2018"></div>
                    <div class="ad-fa8-ellipse y2017"></div>
                    <div class="ad-fa8-ellipse y2016"></div>
                </div>
                <div class="ad-fa8-cards">
                    <div class="ad-fa8-card y2023 highlight">
                        <div class="card-title">聚焦沉淀 专研敏感口腔</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">全面升级—护牙电动牙刷</div>
                            <div class="card-p-s2">素士云感电动牙刷D5上市，王牌产品X3U、D3迭代为X3S、D3S<br>新升级首创 万毛电动牙刷，自研「自适应」马达，护牙护龈，更适合敏感口腔</div>
                            <div class="card-p-label">6</div>
                        </div>
                        <div class="card-paragraph card-paragraph-2">
                            <div class="card-p-s3">守好国货口腔护理每一关</div>
                            <div class="card-p-s4">素士科技 当选中国家用电器协会美健（个护）电器专委会副主席委员单位</div>
                        </div>
                        <div class="card-preview card-preview-2023">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-1.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2022">
                        <div class="card-title">持续创新</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">萌宠新概念，品类再革新</div>
                            <div class="card-p-s2">素士净宠声波电动牙刷D3上市<br>专利 果冻刷头上市，颠覆刷牙舒适度</div>
                            <div class="card-p-label">7</div>
                        </div>
                        <div class="card-preview card-preview-2022">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-2.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2021">
                        <div class="card-title">品类升级</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">电动牙刷品类新升级</div>
                            <div class="card-p-s2">素士壁挂杀菌系列电动牙刷上市，口腔清洁净一步</div>
                        </div>
                        <div class="card-paragraph card-paragraph-2">
                            <div class="card-p-s3">获权威机构认可</div>
                            <div class="card-p-s4">素士被广东省工业和信息厅认定为 “广东省工业设计中心”<br>素士被广东省科学技术厅认定为 “广东省智能个人护理电器工程技术研究中心”</div>
                        </div>
                        <div class="card-preview card-preview-2021">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-3.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2020">
                        <div class="card-title">日新月盛</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">素士首款香氛概念，冲牙新体验</div>
                            <div class="card-p-s2">素士推出全新香氛概念冲牙器W1，为冲牙器特研专用漱口水</div>
                        </div>
                        <div class="card-paragraph card-paragraph-2">
                            <div class="card-p-s3">口腔跨界秀，艺术新合作</div>
                            <div class="card-p-s4">素士 X 梵高博物馆深度定制X3U「绿野玫瑰」联名款电动牙刷<br>素士 X 卢浮宫博物馆联名定制卢浮宫W1冲牙器</div>
                        </div>
                        <div class="card-preview card-preview-2020">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-4.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2019">
                        <div class="card-title">经典诞生</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">经典爆款，获专业、口碑双重认可</div>
                            <div class="card-p-s2">素士王牌电动牙刷X3U上市<br>同年，素士冲牙器W3荣获日本G-Mark设计大奖</div>
                        </div>
                        <div class="card-preview card-preview-2019">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-5.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2018">
                        <div class="card-title">口腔新物种</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">突破传统，上线即畅销</div>
                            <div class="card-p-s2">素士首款冲牙器W3诞生，正畸用户清洁入门首选</div>
                            <div class="card-p-label">8</div>
                        </div>
                        <div class="card-preview card-preview-2018">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-6.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2017">
                        <div class="card-title">技术领先</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">技术攻克，打破国外技术壁垒</div>
                            <div class="card-p-s2">素士成功研发无铜植毛技术，打破欧美厂家垄断的局面</div>
                        </div>
                        <div class="card-preview card-preview-2017">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-7.jpg" />
                        </div>
                    </div>
                    <div class="ad-fa8-card y2016">
                        <div class="card-title">行业先行者</div>
                        <div class="card-paragraph card-paragraph-1">
                            <div class="card-p-s1">第一款电动牙刷上市</div>
                            <div class="card-p-s2">素士首款声波电动牙刷X3上市，成为口腔护理品类的国货先行者</div>
                        </div>
                        <div class="card-preview card-preview-2016">
                            <img src="https://file.soocas.com/sg9_resource/soocas-site/about/08-history-8.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- fa-9 -->
        <div class="full-ad about-fa-9">
            <div class="ad-body">
                注释
                <br>1.数据来源于：卫生计生委第四次全国口腔健康流行病学调查，35-44岁居民中，口腔牙龈出血检出率为87.4%。
                <br>2.数据来源于：《中国成人牙本质敏感和牙齿磨蚀流行病学调查》。
                <br>3.注册号：CNAS L 16961。
                <br>4.指素士品牌首个。
                <br>5.指中国家电电器协会颁发的电动牙刷《团体标准起草单位证书》和电动冲牙器《团体标准起草单位证书》。
                <br>6.相较于素士品牌的其他电动牙刷，该款是首创万毛电动牙刷。
                <br>7.外观设计专利号：ZL 2021 3 0694298.1。
                <br>8.相较于素士品牌的其他冲牙器，该款是入门首选。
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'About',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true,
            beforeBg3: true,
            beforeBg4: true,
            beforeBg5: true,
            beforeBg6: true,
            THE_FALSE: false
        };
    },
    mounted() {
        this.doLazyBinding();
    },
    methods: {
        chooseYear(e) {
            let theSelected = e.target.className.replace("ad-fa-year ", "").replace(" highlight", "");
            switch(theSelected) {
                case "y2023":
                case "y2022":
                case "y2021":
                case "y2020":
                case "y2019":
                case "y2018":
                case "y2017":
                case "y2016":
                    this.showHighlightByClassName(theSelected);
                    break;
                default:
                    break;
            }
        },
        showHighlightByClassName(clsName) {
            let h1 = document.querySelector("div.ad-fa-year.highlight");
            let h2 = document.querySelector("div.ad-fa8-ellipse.highlight");
            let h3 = document.querySelector("div.ad-fa8-card.highlight");
            let t1 = document.querySelector("div.ad-fa-year." + clsName);
            let t2 = document.querySelector("div.ad-fa8-ellipse." + clsName);
            let t3 = document.querySelector("div.ad-fa8-card." + clsName);

            // prevent from repeated operation
            if(h1 && h1.className.indexOf(clsName) > -1) {
                return;
            }

            // cancel highlight class
            if(h1)
                h1.className = h1.className.replace(" highlight", "");
            if(h2)
                h2.className = h2.className.replace(" highlight", "");
            if(h3)
                h3.className = h3.className.replace(" highlight", "");

            // add highlight class
            if(t1)
                t1.className = t1.className + " highlight";
            if(t2)
                t2.className = t2.className + " highlight";
            if(t3)
                t3.className = t3.className + " highlight";
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
                _this.checkBg3();
                _this.checkBg4();
                _this.checkBg5();
                _this.checkBg6();
            };
            _this.checkBg1();
            _this.checkBg2();
            _this.checkBg3();
            _this.checkBg4();
            _this.checkBg5();
            _this.checkBg6();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#about_bg1")) {
                    this.beforeBg1 = this.THE_FALSE;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#about_bg2")) {
                    this.beforeBg2 = this.THE_FALSE;
                }
            }
        },
        checkBg3() {
            if(this.beforeBg3) {
                if(this.$elVisible("#about_bg3")) {
                    this.beforeBg3 = this.THE_FALSE;
                }
            }
        },
        checkBg4() {
            if(this.beforeBg4) {
                if(this.$elVisible("#about_bg4")) {
                    this.beforeBg4 = this.THE_FALSE;
                }
            }
        },
        checkBg5() {
            if(this.beforeBg5) {
                if(this.$elVisible("#about_bg5")) {
                    this.beforeBg5 = this.THE_FALSE;
                }
            }
        },
        checkBg6() {
            if(this.beforeBg6) {
                if(this.$elVisible("#about_bg6")) {
                    this.beforeBg6 = this.THE_FALSE;
                }
            }
        } // End
    }
}
</script>

<style lang="less" scoped>
.about-box{
    position: relative;

    .full-ad{position: relative;height: 56.25vw;}
/*
    .full-ad:nth-child(odd) {background-color: #ddddff;}
    .full-ad:nth-child(even) {background-color: #ddffdd;}
*/
    .full-ad.about-fa-1 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/01.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body {
            position: absolute;
            width: 35.15625vw;
            height: 6.71875vw;
            top: 21.17188vw;
            left: 15.70313vw;

            .ad-title-1 {
                position: absolute;
                width: 35.15625vw;
                height: 4.10156vw;
                top: 0vw;
                left: 0vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.51562vw;
                font-weight: 400;
                line-height: 4.10156vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-title-2 {
                position: absolute;
                width: 17.46094vw;
                height: 1.79688vw;
                top: 4.92188vw;
                left: 0vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 2.14844vw;
                font-weight: 400;
                line-height: 1.40625vw;
                letter-spacing: 0em;
                color: #000000;
            }
        }
    }

    .full-ad.about-fa-2 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/02.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body {
            position: relative;
            width: 68.75vw;
            height: 46.83594vw;
            top: 4.84375vw;
            left: 15.66406vw;

            .ad-group-1{
                position: absolute;
                width: 26.93047vw;
                height: 17.89063vw;
                left: 0vw;
                top: 0vw;

                .ad-g1-s1{
                    position: absolute;
                    width: 26.07617vw;
                    height: 8.55469vw;
                    top: 1.09375vw;
                    left: 0.85430vw;
                    font-family: FZLanTingHei-M-GBK;
                    font-size: 3.20313vw;
                    font-weight: 400;
                    line-height: 3.75vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-g1-s2{
                    position: absolute;
                    width: 6.09375vw;
                    height: 1.95313vw;
                    top: 14.375vw;
                    left: 0.74219vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.875vw;
                    font-weight: 400;
                    line-height: 2.14844vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-g1-s3{
                    position: absolute;
                    width: 10.23438vw;
                    height: 1.05469vw;
                    top: 16.83594vw;
                    left: 0.74219vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 1.44531vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-g1-bracket{
                    .ad-bracket-1,
                    .ad-bracket-2 {
                        position: absolute;
                        width: 1.66328vw;
                        height: 1.44531vw;
                    }
                    .ad-bracket-1{
                        top: 0vw;
                        left: 0vw;
                        border-left: 0.26992vw solid #000000;
                        border-top: 0.23438vw solid #000000;
                    }
                    .ad-bracket-2{
                        top: 12.34375vw;
                        left: 26.19453vw;
                        border-right: 0.26992vw solid #000000;
                        border-bottom: 0.23438vw solid #000000;
                    }
                }
            }

            .ad-group-2{
                position: absolute;
                width: 11.0546875vw;
                height: 5.7421875vw;
                top: 40.703125vw;
                left: 0vw;

                .ad-g2-s1 {
                    position: absolute;
                    width: 11.05469vw;
                    height: 3.75vw;
                    top: 0vw;
                    left: 0vw;
                    font-family: FZLanTingHei-L-GBK-M;
                    font-size: 3.75vw;
                    font-weight: 400;
                    line-height: 3.75vw;
                    letter-spacing: -0.04em;
                    color: #0078FF;
                }

                .ad-g2-s2 {
                    position: absolute;
                    width: 0.39062vw;
                    height: 0.78125vw;
                    top: 0vw;
                    left: 10.58594vw;
                    font-family: FZLanTingHei-L-GBK-M;
                    font-size: 0.625vw;
                    font-weight: 400;
                    line-height: 0.78125vw;
                    letter-spacing: -0.04em;
                    color: #000000;
                }

                .ad-g2-s3 {
                    position: absolute;
                    width: 10.58594vw;
                    height: 1.79688vw;
                    top: 3.94531vw;
                    left: 0vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.5625vw;
                    font-weight: 400;
                    line-height: 1.79688vw;
                    letter-spacing: -0.04em;
                    color: rgba(0,0,0,0.6);
                }
            }

            .ad-group-3{
                position: absolute;
                width: 9.84375vw;
                height: 5.546875vw;
                top: 41.28906vw;
                left: 58.90625vw;

                .ad-g3-s1{
                    position: absolute;
                    width: 5.42969vw;
                    height: 3.75vw;
                    top: 0vw;
                    left: 0vw;
                    font-family: FZLanTingHei-L-GBK-M;
                    font-size: 3.75vw;
                    font-weight: 400;
                    line-height: 3.75vw;
                    letter-spacing: -0.04em;
                    color: #0078FF;
                }

                .ad-g3-s2{
                    position: absolute;
                    width: 0.39063vw;
                    height: 0.78125vw;
                    top: 0vw;
                    left: 5.46875vw;
                    font-family: FZLanTingHei-L-GBK-M;
                    font-size: 0.625vw;
                    font-weight: 400;
                    line-height: 0.78125vw;
                    letter-spacing: -0.04em;
                    color: #000000;
                }

                .ad-g3-s3{
                    position: absolute;
                    width: 3.75vw;
                    height: 2.1875vw;
                    top: 1.32812vw;
                    left: 5.9375vw;
                    font-family: FZLanTingHei-M-GBK;
                    font-size: 1.875vw;
                    font-weight: 400;
                    line-height: 2.1875vw;
                    letter-spacing: -0.04em;
                    color: #0078FF;
                }

                .ad-g3-s4{
                    position: absolute;
                    width: 9.53125vw;
                    height: 1.60156vw;
                    top: 3.94531vw;
                    left: 0.3125vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.40625vw;
                    font-weight: 400;
                    line-height: 1.60156vw;
                    letter-spacing: -0.04em;
                    color: rgba(0,0,0,0.6);
                }
            }
        }
    }

    .full-ad.about-fa-3 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/03.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body{
            position: relative;
            width: 69.41406vw;
            height: 19.25781vw;
            top: 18.51563vw;
            left: 15.3125vw;

            .ad-fa3-s1{
                position: absolute;
                width: 10vw;
                height: 2.92968vw;
                top: 0vw;
                left: 8.90625vw;
                font-family: FZLanTingHeiS-DB-GB;
                font-size: 2.5vw;
                font-weight: 400;
                line-height: 2.92969vw;
                letter-spacing: 0em;
                color: #0078ff;
            }

            .ad-fa3-s2{
                position: absolute;
                width: 10.3125vw;
                height: 0.03906vw;
                top: 5.85937vw;
                left: 8.75vw;
                background-color: rgba(0,0,0,0.4);
            }

            .ad-fa3-s3{
                position: absolute;
                width: 14.49219vw;
                height: 1.79688vw;
                top: 8.82813vw;
                left: 6.64063vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 1.5625vw;
                font-weight: 400;
                line-height: 1.79688vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-fa3-s4{
                position: absolute;
                width: 27.77344vw;
                height: 7.89063vw;
                top: 11.36719vw;
                left: 0vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.35938vw;
                font-weight: 400;
                line-height: 3.94531vw;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
            }

            .ad-fa3-s5{
                position: absolute;
                width: 10.03906vw;
                height: 2.92969vw;
                top: 0vw;
                left: 52.53906vw;
                font-family: FZLanTingHeiS-DB-GB;
                font-size: 2.5vw;
                font-weight: 400;
                line-height: 2.92969vw;
                letter-spacing: 0em;
                color: #0078FF;
            }

            .ad-fa3-s6{
                position: absolute;
                width: 10.3125vw;
                height: 0.03906vw;
                top: 5.85938vw;
                left: 52.38281vw;
                background-color: rgba(0,0,0,0.4);
            }

            .ad-fa3-s7{
                position: absolute;
                width: 23.75vw;
                height: 1.79688vw;
                top: 8.82813vw;
                left: 45.66406vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 1.5625vw;
                font-weight: 400;
                line-height: 1.79688vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-fa3-s8{
                position: absolute;
                width: 20.15625vw;
                height: 7.89063vw;
                top: 11.36719vw;
                left: 47.46094vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.35938vw;
                font-weight: 400;
                line-height: 3.94531vw;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
            }
        }
    }

    .full-ad.about-fa-4 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/04.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body {
            position: relative;
            width: 38.67188vw;
            height: 42.5vw;
            top: 7.26563vw;
            left: 26.36719vw;

            .ad-fa4-box-1 {
                position: absolute;
                width: 28.125vw;
                height: 7.10938vw;
                top: 0vw;
                left: 9.53125vw;

                .ad-fa4-b1-s1 {
                    position: absolute;
                    width: 28.125vw;
                    height: 4.10156vw;
                    top: 0vw;
                    left: 0vw;
                    font-family: FZLanTingHei-M-GBK;
                    font-size: 3.51563vw;
                    font-weight: 400;
                    line-height: 4.10156vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-fa4-b1-s2 {
                    position: absolute;
                    width: 25.89844vw;
                    height: 2.46094vw;
                    top: 4.64844vw;
                    left: 1.13281vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 2.14844vw;
                    font-weight: 400;
                    line-height: 2.46094vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-fa4-b1-s3 {
                    position: absolute;
                    width: 0.39063vw;
                    height: 0.66406vw;
                    top: 4.64844vw;
                    left: 27.03125vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 0.58594vw;
                    font-weight: 400;
                    line-height: 0.66406vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
            }

            .ad-fa4-box-2 {
                position: absolute;
                width: 38.67188vw;
                height: 2.92969vw;
                top: 39.57031vw;
                left: 0vw;

                .ad-fa4-b2-g1 {
                    position: absolute;
                    width: 2.89063vw;
                    height: 2.92969vw;
                    top: 0vw;
                    left: 0vw;

                    .ad-fa4-b2g1-up {
                        position: absolute;
                        width: 2.57813vw;
                        height: 1.60156vw;
                        top: 0vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.40625vw;
                        font-weight: 400;
                        line-height: 1.60156vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .ad-fa4-b2g1-dn {
                        position: absolute;
                        width: 2.8125vw;
                        height: 1.05469vw;
                        top: 1.875vw;
                        left: 0.07813vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.9375vw;
                        font-weight: 400;
                        line-height: 1.05469vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa4-b2-g2 {
                    position: absolute;
                    width: 6.5625vw;
                    height: 2.92969vw;
                    top: 0vw;
                    left: 5.97656vw;

                    .ad-fa4-b2g2-up {
                        position: absolute;
                        width: 1.75781vw;
                        height: 1.60156vw;
                        top: 0vw;
                        left: 2.53906vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.40625vw;
                        font-weight: 400;
                        line-height: 1.60156vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .ad-fa4-b2g2-dn {
                        position: absolute;
                        width: 6.5625vw;
                        height: 1.05469vw;
                        top: 1.875vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.9375vw;
                        font-weight: 400;
                        line-height: 1.05469vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa4-b2-g3 {
                    position: absolute;
                    width: 5.625vw;
                    height: 2.92969vw;
                    top: 0vw;
                    left: 15.625vw;

                    .ad-fa4-b2g3-up {
                        position: absolute;
                        width: 2.57813vw;
                        height: 1.60156vw;
                        top: 0vw;
                        left: 1.36719vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.40625vw;
                        font-weight: 400;
                        line-height: 1.60156vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .ad-fa4-b2g3-dn {
                        position: absolute;
                        width: 5.625vw;
                        height: 1.05469vw;
                        top: 1.875vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.9375vw;
                        font-weight: 400;
                        line-height: 1.05469vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa4-b2-g4 {
                    position: absolute;
                    width: 5.625vw;
                    height: 2.92969vw;
                    top: 0vw;
                    left: 24.33594vw;

                    .ad-fa4-b2g4-up {
                        position: absolute;
                        width: 5.625vw;
                        height: 1.60156vw;
                        top: 0vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.40625vw;
                        font-weight: 400;
                        line-height: 1.60156vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .ad-fa4-b2g4-dn {
                        position: absolute;
                        width: 3.75vw;
                        height: 1.05469vw;
                        top: 1.875vw;
                        left: 1.32813vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.9375vw;
                        font-weight: 400;
                        line-height: 1.05469vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa4-b2-g5 {
                    position: absolute;
                    width: 5.625vw;
                    height: 2.92969vw;
                    top: 0vw;
                    left: 33.04688vw;

                    .ad-fa4-b2g5-up {
                        position: absolute;
                        width: 5.625vw;
                        height: 1.60156vw;
                        top: 0vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.40625vw;
                        font-weight: 400;
                        line-height: 1.60156vw;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000000;
                    }

                    .ad-fa4-b2g5-dn {
                        position: absolute;
                        width: 3.75vw;
                        height: 1.05469vw;
                        top: 1.875vw;
                        left: 1.32813vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.9375vw;
                        font-weight: 400;
                        line-height: 1.05469vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa4-b2-l1 {
                    position: absolute;
                    width: 0.03906vw;
                    height: 2.57812vw;
                    top: 0.35156vw;
                    left: 4.41406vw;
                    background-color: rgba(0,0,0,0.3);
                }

                .ad-fa4-b2-l2 {
                    position: absolute;
                    width: 0.03906vw;
                    height: 2.57812vw;
                    top: 0.35156vw;
                    left: 14.0625vw;
                    background-color: rgba(0,0,0,0.3);
                }

                .ad-fa4-b2-l3 {
                    position: absolute;
                    width: 0.03906vw;
                    height: 2.57812vw;
                    top: 0.35156vw;
                    left: 22.77344vw;
                    background-color: rgba(0,0,0,0.3);
                }

                .ad-fa4-b2-l4 {
                    position: absolute;
                    width: 0.03906vw;
                    height: 2.57812vw;
                    top: 0.35156vw;
                    left: 31.48438vw;
                    background-color: rgba(0,0,0,0.3);
                }
            }
        }
    }

    .full-ad.about-fa-5 {
        .ad-bg {
            position: relative;
            left: 0vw;
            top: 0vw;
            width: 100%;
            height: 100%;
            overflow: hidden;

            video {
                width: auto;
                height: 100%;
                margin: 0;
                padding: 0;
                border: 0;
            }
        }
        .ad-body {
            position: absolute;
            width: 46.67969vw;
            height: 9.96093vw;
            top: 7.30469vw;
            left: 26.64062vw;

            .ad-fa5-slogan {
                position: absolute;
                width: 46.67969vw;
                height: 4.10156vw;
                top: 0vw;
                left: 0vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.51563vw;
                font-weight: 400;
                line-height: 4.10156vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-fa5-desc {
                position: absolute;
                width: 39.72656vw;
                height: 3.75vw;
                top: 6.21094vw;
                left: 3.47656vw;

                .ad-fa5-desc-left {
                    position: absolute;
                    width: 16.21094vw;
                    height: 3.75vw;
                    top: 0vw;
                    left: 0vw;


                    .ad-fa5-left-digit {
                        position: absolute;
                        width: 8.20313vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-family: FZLanTingHei-M-GBK;
                        font-size: 3.125vw;
                        font-weight: 400;
                        line-height: 3.75vw;
                        letter-spacing: 0em;
                        color: #0078FF;
                    }

                    .ad-fa5-left-suffix {
                        position: absolute;
                        width: 7.8125vw;
                        height: 2.5vw;
                        top: 1.25vw;
                        left: 8.39844vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.5625vw;
                        font-weight: 400;
                        line-height: 2.5vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }

                .ad-fa5-desc-right {
                    position: absolute;
                    width: 16.17188vw;
                    height: 3.75vw;
                    top: 0vw;
                    left: 23.55469vw;

                    .ad-fa5-right-digit {
                        position: absolute;
                        width: 9.0625vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-family: FZLanTingHei-M-GBK;
                        font-size: 3.125vw;
                        font-weight: 400;
                        line-height: 3.75vw;
                        letter-spacing: 0em;
                        color: #0078FF;
                    }

                    .ad-fa5-right-suffix {
                        position: absolute;
                        width: 7.8125vw;
                        height: 2.5vw;
                        top: 1.25vw;
                        left: 8.35938vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.5625vw;
                        font-weight: 400;
                        line-height: 2.5vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }
                }

                .ad-fa5-desc-splitter {
                    position: absolute;
                    width: 0.03906vw;
                    height: 3.47656vw;
                    top: 0.15625vw;
                    left: 19.53125vw;
                    background-color: rgba(0,0,0,0.3);
                }
            }
        }
    }

    .full-ad.about-fa-6 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/06.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body {
            position: relative;
            left: 0vw;
            top: 0vw;
            width: 100%;
            min-width: 100%;
            height: 100%;

            .ad-fa6-basis-1,
            .ad-fa6-basis-2 {
                position: absolute;
                top: 26.09375vw;
                height: 4.10156vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.51563vw;
                font-weight: 400;
                line-height: 4.10156vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-fa6-basis-1 {
                width: 17.57813vw;
                left: 8.82813vw;
            }

            .ad-fa6-basis-2 {
                width: 25.70313vw;
                left: 69.84375vw;
            }
        }
    }

    .full-ad.about-fa-7 {
        background-image: url("https://file.soocas.com/sg9_resource/soocas-site/about/07.jpg");
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        .ad-body{
            position: relative;
            width: 43.16406vw;
            height: 10.07813vw;
            top: 7.46094vw;
            left: 28.4375vw;

            .ad-fa7-s1 {
                position: absolute;
                width: 43.16406vw;
                height: 4.10156vw;
                top: 0vw;
                left: 0vw;
                font-family: FZLanTingHei-M-GBK;
                font-size: 3.51563vw;
                font-weight: 400;
                line-height: 4.10156vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad-fa7-box {
                position: absolute;
                width: 41.5625vw;
                height: 3.63281vw;
                top: 6.44531vw;
                left: 0.78125vw;

                .ad-fa7-left {
                    position: absolute;
                    width: 13.71094vw;
                    height: 3.63281vw;
                    top: 0vw;
                    left: 0vw;

                    .ad-fa7-left-s1 {
                        position: absolute;
                        width: 13.67188vw;
                        height: 2.22656vw;
                        top: 0vw;
                        left: 0.03906vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.95313vw;
                        font-weight: 400;
                        line-height: 2.22656vw;
                        letter-spacing: 0em;
                        color: #0078FF;
                    }

                    .ad-fa7-left-s1-label {
                        position: absolute;
                        width: 0.39063vw;
                        height: 0.66406vw;
                        top: 0vw;
                        left: 13.68vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 0.58594vw;
                        font-weight: 400;
                        line-height: 0.66406vw;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .ad-fa7-left-s2 {
                        position: absolute;
                        width: 10.15625vw;
                        height: 1.17188vw;
                        top: 2.46094vw;
                        left: 1.79688vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.01563vw;
                        font-weight: 400;
                        line-height: 1.17188vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa7-right {
                    position: absolute;
                    width: 19.60938vw;
                    height: 3.59375vw;
                    top: 0.03906vw;
                    left: 21.95313vw;

                    .ad-fa7-right-s1 {
                        position: absolute;
                        width: 13.125vw;
                        height: 2.14844vw;
                        top: 0vw;
                        left: 3.24219vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.875vw;
                        font-weight: 400;
                        line-height: 2.14844vw;
                        letter-spacing: 0em;
                        color: #0078FF;
                    }

                    .ad-fa7-right-s2 {
                        position: absolute;
                        width: 19.60938vw;
                        height: 1.17188vw;
                        top: 2.42188vw;
                        left: 0vw;
                        font-family: FZLanTingHeiS-R-GB;
                        font-size: 1.01563vw;
                        font-weight: 400;
                        line-height: 1.17188vw;
                        letter-spacing: 0em;
                        color: rgba(0,0,0,0.6);
                    }
                }

                .ad-fa7-splitter {
                    position: absolute;
                    width: 0.03906vw;
                    height: 3.16406vw;
                    top: 0.23438vw;
                    left: 17.8125vw;
                    background-color: rgba(0,0,0,0.3);
                }
            }
        }
    }

    .full-ad.about-fa-8 {
        background-color: transparent;
        height: 58.08595vw;
        overflow-y: hidden;

        .ad-body{
            position: relative;
            width: 69.0625vw;
            height: 48.67188vw;
            top: 9.41406vw;
            left: 15.66406vw;

            .ad-fa8-title {
                position: absolute;
                width: 29.6875vw;
                height: 6.91406vw;
                top: 0vw;
                left: 19.49219vw;

                .ad-title-s1 {
                    position: absolute;
                    width: 14.0625vw;
                    height: 4.10156vw;
                    top: 0vw;
                    left: 7.8125vw;
                    font-family: FZLanTingHei-M-GBK;
                    font-size: 3.51563vw;
                    font-weight: 400;
                    line-height: 4.10156vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad-title-s2 {
                    position: absolute;
                    width: 29.6875vw;
                    height: 2.14844vw;
                    top: 4.76563vw;
                    left: 0vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.875vw;
                    font-weight: 400;
                    line-height: 2.14844vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
            }

            .ad-fa8-years {
                position: absolute;
                width: 69.0625vw;
                height: 2.1875vw;
                top: 15.07812vw;
                left: 0vw;

                .ad-fa-year {
                    position: absolute;
                    top: 0vw;
                    height: 2.1875vw;
                    font-family: FZLanTingHeiS-DB-GB;
                    font-size: 1.875vw;
                    font-weight: 400;
                    line-height: 2.1875vw;
                    letter-spacing: 0em;
                    text-align: left;
                    color: rgba(0,0,0,0.4);
                    cursor: pointer;
                }

                .ad-fa-year.highlight {
                    color: #000000;
                }

                .ad-fa-year.y2023{
                    width: 4.92188vw;
                    height: 2.14844vw;
                    left: 0vw;
                }
                .ad-fa-year.y2022{
                    width: 4.96094vw;
                    left: 9.375vw;
                }
                .ad-fa-year.y2021{
                    width: 4.6875vw;
                    left: 18.67188vw;
                }
                .ad-fa-year.y2020{
                    width: 5vw;
                    left: 27.73438vw;
                }
                .ad-fa-year.y2019{
                    width: 5vw;
                    left: 37.07031vw;
                }
                .ad-fa-year.y2018{
                    width: 4.76563vw;
                    left: 46.17188vw;
                }
                .ad-fa-year.y2017{
                    width: 4.72656vw;
                    left: 55.27344vw;
                }
                .ad-fa-year.y2016{
                    width: 4.72656vw;
                    left: 64.33594vw;
                }
            }

            .ad-fa8-axis {
                position: absolute;
                width: 68.98438vw;
                height: 0.15626vw;
                top: 18.24217vw;
                left: 0vw;
                background-color: #D9D9D9;
            }

            .ad-fa8-ellipse {
                position: absolute;
                top: 18.04688vw;
                left: 0vw;
                width: 68.98438vw;
                height: 0.58594vw;

                .ad-fa8-ellipse {
                    position: absolute;
                    top: 0vw;
                    width: 0.58594vw;
                    height: 0.58594vw;
                    border-radius: 0.29297vw;
                    background-color: #000000;
                    display: none;
                }

                .ad-fa8-ellipse.highlight {
                    display: block;
                }

                .ad-fa8-ellipse.y2023 {
                    left: 2.1875vw;
                }

                .ad-fa8-ellipse.y2022 {
                    left: 11.5625vw;
                }

                .ad-fa8-ellipse.y2021 {
                    left: 20.74219vw;
                }

                .ad-fa8-ellipse.y2020 {
                    left: 29.92188vw;
                }

                .ad-fa8-ellipse.y2019 {
                    left: 39.10156vw;
                }

                .ad-fa8-ellipse.y2018 {
                    left: 48.28125vw;
                }

                .ad-fa8-ellipse.y2017 {
                    left: 57.34375vw;
                }

                .ad-fa8-ellipse.y2016 {
                    left: 66.40625vw;
                }
            }

            .ad-fa8-cards {
                position: absolute;
                top: 19.45313vw;
                left: 0vw;
                min-width: 28.98438vw;
                max-width: 68.98438vw;
                height: 29.21875vw;
                max-height: 29.21875vw;

                .ad-fa8-card {
                    display: none;
                    position: absolute;
                    left: 0vw;
                    top: 0vw;

                    .card-title {
                        position: absolute;
                        font-family: FZLanTingHeiS-DB-GB;
                        font-weight: 400;
                        letter-spacing: 0em;
                        color: #000000;
                    }

                    .card-paragraph{
                        position: absolute;

                        .card-p-s1{
                            position: absolute;
                            color: #000000;
                        }

                        .card-p-s2{
                            position: absolute;
                            color: rgba(0,0,0,0.6);
                        }

                        .card-p-s3{
                            position: absolute;
                            color: #000000;
                        }

                        .card-p-s4{
                            position: absolute;
                            color: rgba(0,0,0,0.6);
                        }
                    }

                    .card-preview {
                        position: absolute;
                        border-radius: 1.17188vw;
                        background-color: #D9D9D9;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .ad-fa8-card.highlight{
                    display: block;
                }

                .ad-fa8-card.y2023 {
                    width: 28.98438vw;
                    height: 29.21875vw;

                    .card-title {
                        width: 26.36719vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }

                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 4.10156vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1 {
                            width: 13.75vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }

                        .card-p-s2 {
                            width: 28.98438vw;
                            height: 2.34375vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }

                        .card-p-label {
                            position: absolute;
                            width: 0.19531vw;
                            height: 0.54688vw;
                            top: 2.92969vw;
                            left: 3.90625vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.27344vw;
                            font-weight: 400;
                            line-height: 0.54688vw;
                            letter-spacing: 0em;
                            color: rgba(0,0,0,0.6);
                        }
                    }

                    .card-paragraph.card-paragraph-2 {
                        width: 28.98438vw;
                        height: 2.96875vw;
                        top: 10.42969vw;
                        left: 0vw;

                        .card-p-s3 {
                            width: 14.10156vw;
                            height: 1.17188vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }

                        .card-p-s4 {
                            width: 28.98438vw;
                            height: 0.9375vw;
                            top: 2.03125vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 0.78125vw;
                            letter-spacing: 0em;
                        }
                    }

                    .card-preview.card-preview-2023 {
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 15.15625vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2022 {
                    width: 28.98438vw;
                    height: 24.92188vw;

                    .card-title {
                        width: 11.36719vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }

                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 3.90625vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 14.10156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 2.14844vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                        .card-p-label {
                            position: absolute;
                            width: 0.195313vw;
                            height: 0.54688vw;
                            top: 2.85156vw;
                            left: 1.5625vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.27344vw;
                            font-weight: 400;
                            line-height: 0.54688vw;
                            letter-spacing: 0em;
                            color: rgba(0,0,0,0.6);
                        }
                    }

                    .card-preview.card-preview-2022{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 10.85938vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2021 {
                    width: 31.28906vw;
                    height: 29.45312vw;

                    .card-title {
                        width: 10.66406vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }

                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 2.92969vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 11.60156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }

                        .card-p-s2{
                            width: 28.98438vw;
                            height: 1.17188vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-paragraph.card-paragraph-2 {
                        width: 31.28906vw;
                        height: 4.14062vw;
                        top: 9.29688vw;
                        left: 0vw;

                        .card-p-s3{
                            width: 8.75vw;
                            height: 1.17188vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }

                        .card-p-s4{
                            width: 31.28906vw;
                            height: 2.10938vw;
                            top: 2.03125vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-preview.card-preview-2021{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 15.39063vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2020 {
                    width: 31.28906vw;
                    height: 29.45312vw;

                    .card-title {
                        width: 10.66406vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }
                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 2.92969vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 17.85156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 1.17188vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-paragraph.card-paragraph-2 {
                        width: 31.28906vw;
                        height: 4.14063vw;
                        top: 9.29688vw;
                        left: 0vw;

                        .card-p-s3{
                            width: 14.10156vw;
                            height: 1.17188vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s4{
                            width: 31.28906vw;
                            height: 31.28906vw;
                            top: 2.03125vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-preview.card-preview-2020{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 15.39063vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2019 {
                    width: 28.98438vw;
                    height: 24.92188vw;

                    .card-title {
                        width: 10vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }
                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 4.10156vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 19.10156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 2.34375vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-preview.card-preview-2019{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 10.85938vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2018 {
                    width: 28.98438vw;
                    height: 23.78906vw;

                    .card-title {
                        width: 12.5vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }
                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 2.92969vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 12.85156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 1.17188vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                        .card-p-label{
                            position: absolute;
                            width: 0.195313vw;
                            height: 0.54688vw;
                            top: 1.75781vw;
                            left: 17vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.27344vw;
                            font-weight: 400;
                            line-height: 0.54688vw;
                            letter-spacing: 0em;
                            color: rgba(0,0,0,0.6);
                        }
                    }
                    .card-preview.card-preview-2018{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 9.72656vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2017 {
                    width: 28.98438vw;
                    height: 23.78906vw;

                    .card-title {
                        width: 10.66406vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }
                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 2.92969vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 16.60156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 1.17188vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-preview.card-preview-2017{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 9.72656vw;
                        left: 0vw;
                    }
                }

                .ad-fa8-card.y2016 {
                    width: 28.98438vw;
                    height: 23.78906vw;

                    .card-title {
                        width: 14.49219vw;
                        height: 3.75vw;
                        top: 0vw;
                        left: 0vw;
                        font-size: 2.5vw;
                        line-height: 3.75vw;
                    }
                    .card-paragraph.card-paragraph-1 {
                        width: 28.98438vw;
                        height: 2.92968vw;
                        top: 5.03906vw;
                        left: 0vw;

                        .card-p-s1{
                            width: 11.60156vw;
                            height: 1.25vw;
                            top: 0vw;
                            left: 0vw;
                            font-family: FZLanTingHei-M-GBK;
                            font-size: 1.25vw;
                            font-weight: 400;
                            line-height: 1.25vw;
                            letter-spacing: 0em;
                        }
                        .card-p-s2{
                            width: 28.98438vw;
                            height: 1.17188vw;
                            top: 1.75781vw;
                            left: 0vw;
                            font-family: FZLanTingHeiS-R-GB;
                            font-size: 0.78125vw;
                            font-weight: 400;
                            line-height: 1.17188vw;
                            letter-spacing: 0em;
                        }
                    }
                    .card-preview.card-preview-2016{
                        width: 23.4375vw;
                        height: 14.0625vw;
                        top: 9.72656vw;
                        left: 0vw;
                    }
                }
            }
        }
    }

    .full-ad.about-fa-9 {
        background-color: transparent;
        height: 26.91406vw;

        .ad-body{
            position: relative;
            width: 35.97656vw;
            height: 12.92969vw;
            top: 8.78906vw;
            left: 32.03125vw;
            font-family: FZLanTingHei-L-GBK-M;
            font-size: 0.70313vw;
            font-weight: 400;
            line-height: 1.36719vw;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(0,0,0,0.5);
        }
    }

}

.about-box .full-ad.about-fa-1.bg-loading,
.about-box .full-ad.about-fa-2.bg-loading,
.about-box .full-ad.about-fa-3.bg-loading,
.about-box .full-ad.about-fa-4.bg-loading,
.about-box .full-ad.about-fa-6.bg-loading,
.about-box .full-ad.about-fa-7.bg-loading {
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-loading.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

</style>
