<template>
    <div class="body">
        <!-- full-width AD -->
        <div id="brush_bg1" class="full-ad bg1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body">
                <div class="ad-slogon">告别硬震猛<br>护牙不伤龈</div>
                <div class="ad-digital-label">1</div>
                <div class="ad-product">D5 云感刷</div>
                <div class="ad-button clickable" @click="$router.push('/d5')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div id="brush_bg2" class="full-ad full-ad-3 bg2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-slogon">护牙有专利<br>净齿治愈系</div>
                <div class="ad-digital-label">2</div>
                <div class="ad-product">D3S 情侣猫爪刷</div>
                <div class="ad-button clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665853381051', 'd3s')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div id="brush_bg3" class="full-ad full-ad-3 bg3" :class="{'bg-loading':beforeBg3}">
            <div class="ad-body">
                <div class="ad-slogon">告别牙磨损<br>亮白不伤牙</div>
                <div class="ad-digital-label">1</div>
                <div class="ad-product">X3S 经典大白刷</div>
                <div class="ad-button clickable" @click="openLink('https://detail.tmall.com/item.htm?id=544747042379', 'x3s')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div class="desc">
            <div class="desc-body">
                注释<br>
                1.数据来源于素士实验室,指39名受试者使用素士云感刷头后自我评估数据,仅供参考,实际效果因人而异。<br>
                2.实用新型专利号:ZL201720473306.8。
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Brush',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true,
            beforeBg3: true
        };
    },
    mounted() {
        this.doLazyBinding();
    },
    methods: {
        openLink(url, name) {
            window.open(url, name);
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
                _this.checkBg3();
            };
            _this.checkBg1();
            _this.checkBg2();
            _this.checkBg3();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#brush_bg1")) {
                    this.beforeBg1 = false;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#brush_bg2")) {
                    this.beforeBg2 = false;
                }
            }
        },
        checkBg3() {
            if(this.beforeBg3) {
                if(this.$elVisible("#brush_bg3")) {
                    this.beforeBg3 = false;
                }
            }
        } // End
    }
}
</script>

<!-- Style Priority: vue.less > index.css -->

<style lang="less" scoped>

.body .full-ad.bg1 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-brush/02-d5.jpg');
}
.body .full-ad-3.bg2 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-brush/d3s.jpg');
}
.body .full-ad-3.bg3 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-brush/x3s.jpg');
    margin-bottom: 3.86719vw;
}

.body .full-ad.bg1.bg-loading,
.body .full-ad-3.bg2.bg-loading,
.body .full-ad-3.bg3.bg-loading,{
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-transparent.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.body .desc {
    height: 4.10156vw;
    margin-bottom:3.78906vw;
}

.body {
    .full-ad{
        .ad-body {
            .ad-digital-label {
                position: absolute;
                width: 0.3125vw;
                height: 0.78125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.46875vw;
                font-weight: 400;
                line-height: 0.78125vw;
                letter-spacing: -0.13em;
                color: #000000;
            }
        }
    }

    .full-ad.bg1 {
        .ad-body {
            top: 16.32813vw;
            left: 21.36719vw;
            .ad-digital-label {
                top: 2.69531vw;
                left: 12.5vw;
            }
        }
    }

    .full-ad.full-ad-3.bg2 {
        .ad-body {
            top: 16.05469vw;
            left: 21.36719vw;
            .ad-digital-label {
                top: 0vw;
                left: 12.5vw;
            }
        }
    }

    .full-ad.full-ad-3.bg3 {
        .ad-body {
            top: 16.36719vw;
            left: 21.36719vw;
            .ad-digital-label {
                top: 2.61719vw;
                left: 12.5vw;
            }
        }
    }

}

</style>
