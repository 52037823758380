<template>
    <div class="body">
        <!-- full-width AD -->
        <div id="floss_bg1" class="full-ad bg1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body">
                <div class="ad-slogon">新手入门<br>护龈不易出血</div>
                <div class="ad-product">W3P 专业便携冲牙器</div>
                <div class="ad-button clickable" @click="openLink('https://detail.tmall.com/item.htm?id=597419089214', 'w3p')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div id="floss_bg2" class="full-ad full-ad-3 bg2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-slogon">专利稳压护龈<br>冲牙=香氛SPA</div>
                <div class="ad-digital-label">1</div>
                <div class="ad-product">W1 香氛便携冲牙器</div>
                <div class="ad-button ad-button-special clickable" @click="openLink('https://detail.tmall.com/item.htm?id=623614187391', 'w1')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div class="desc">
            <div class="desc-body">
                注释<br>
                1.实用新型专利号:ZL201810722634.6。
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WaterFloss',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true
        };
    },
    mounted() {
        this.doLazyBinding();
    },
    methods: {
        openLink(url, name) {
            window.open(url, name);
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
            };
            _this.checkBg1();
            _this.checkBg2();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#floss_bg1")) {
                    this.beforeBg1 = false;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#floss_bg2")) {
                    this.beforeBg2 = false;
                }
            }
        } // End
    }
}
</script>

<style lang="less" scoped>

.body .full-ad.bg1 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-waterfloss/w3p.jpg');
}
.body .full-ad-3.bg2 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-waterfloss/w1.jpg');
    margin-bottom: 3.90625vw;
}

.body .full-ad.bg1.bg-loading,
.body .full-ad-3.bg2.bg-loading {
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-transparent.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.body .desc {
    height: 2.38282vw;
    margin-bottom:4.17969vw;
}

.body {
    .full-ad{
        .ad-body {
            .ad-digital-label {
                position: absolute;
                width: 0.3125vw;
                height: 0.78125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.46875vw;
                font-weight: 400;
                line-height: 0.78125vw;
                letter-spacing: -0.13em;
                color: #000000;
            }
        }
    }

    .full-ad.bg1{
        .ad-body {
            top: 16.32813vw;
            left: 21.36719vw;
        }
    }
    .full-ad.full-ad-3.bg2{
        .ad-body {
            top: 16.28906vw;
            left: 21.36719vw;
            .ad-digital-label {
                top: 0vw;
                left: 15vw;
            }
        }
    }
}

/*
.body .full-ad .ad-body .ad-button.ad-button-special .left-6 {
    transform: translateY(-0.06vw);
}
*/

</style>
