import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home.vue";
import Brush from "../views/Brush.vue";
import WaterFloss from "../views/WaterFloss.vue";
import Accessory from "../views/Accessory.vue";
import D5 from "../views/D5.vue";
import About from "../views/About.vue";
import Agreement from "../views/Agreement.vue";

import Postsale from "../views/Postsale.vue";

import Subscribe from "../views/Subscribe.vue";
import Contact from "../views/Contact.vue";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {})
};

const routes = [
    {
        path: '/',
        name: '/',
        meta: {
            title: "素士官网"
        },
        component: Home
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            title: "素士官网"
        },
        component: Home
    },
    {
        path: '/brush',
        name: 'Brush',
        meta: {
            title: "电动牙刷"
        },
        component: Brush
    },
    {
        path: '/waterfloss',
        name: 'WaterFloss',
        meta: {
            title: "冲牙器"
        },
        component: WaterFloss
    },
    {
        path: '/accessory',
        name: 'Accessory',
        meta: {
            title: "刷头配件"
        },
        component: Accessory
    },
    {
        path: '/d5',
        name: 'D5',
        meta: {
            title: "D5详情"
        },
        component: D5
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: "关于素士"
        },
        component: About
    },
    {
        path: '/agreement',
        name: 'Agreement',
        meta: {
            title: "服务协议"
        },
        component: Agreement
    },
    {
        path: '/postsale',
        name: 'Postsale',
        meta: {
            title: "售后服务"
        },
        component: Postsale
    },
    {
        path: '/subscribe',
        name: 'Subscribe',
        meta: {
            title: "关注我们"
        },
        component: Subscribe
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: "联系我们"
        },
        component: Contact
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router
