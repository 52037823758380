<template>
    <div class="body">
        <!-- full-width AD -->
        <div id="accessory_bg1" class="full-ad bg1" :class="{'bg-loading':beforeBg1}">
            <div class="ad-body">
                <div class="ad-slogon">这下舒服了<br>云感包裹式净齿</div>
                <div class="ad-product">云感刷头</div>
                <div class="ad-button clickable" @click="openLink('https://detail.tmall.com/item.htm?id=538259798447&skuId=5067564280858', 'cloud')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>
        <div id="accessory_bg2" class="full-ad full-ad-3 bg2" :class="{'bg-loading':beforeBg2}">
            <div class="ad-body">
                <div class="ad-slogon">云感亮白<br>炫出大白牙</div>
                <div class="ad-product">云感亮白刷头</div>
                <div class="ad-button clickable" @click="openLink('https://detail.tmall.com/item.htm?id=665854557738&skuId=5071707865635', 'cloud-brightly')"><span class="left-6"></span><span class="right-txt">了解更多</span></div>
            </div>
        </div>

        <!-- half-width AD -->
        <div class="half-width-ad half-ad-1 half-ad-3">
            <div class="ad-box left">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">深入齿缝 专效护龈</div>
                    <div class="ad-b-product">W3P专用喷嘴</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://detail.tmall.com/item.htm?id=632472076722', 'w3p-spray-nozzle')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img1">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/l2-accessories/spray-nozzle.png" />
                </div>
            </div>
            <div class="ad-box right">
                <div class="ad-b-desc">
                    <div class="ad-b-slogon">清洁齿缝 一线搞定</div>
                    <div class="ad-b-product">50支装牙线棒</div>
                    <div class="ad-b-button">
                        <span class="adbb clickable" @click="openLink('https://detail.tmall.com/item.htm?id=555431259993&skuId=5001551882817', 'picker')"><span class="left-4">&nbsp;</span><span class="right-text">了解更多</span></span>
                    </div>
                </div>
                <div class="ad-b-img img2">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/l2-accessories/floss-pick.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accessory',
    components: {
    },
    data() {
        return {
            beforeBg1: true,
            beforeBg2: true
        };
    },
    mounted() {
        this.doLazyBinding();
    },
    methods: {
        openLink(url, name) {
            window.open(url, name);
        },
        doLazyBinding() {
            let _this = this;
            window.scrollTo(0, 0);
            window.onscroll = function(){
                _this.checkBg1();
                _this.checkBg2();
            };
            _this.checkBg1();
            _this.checkBg2();
        },
        checkBg1() {
            if(this.beforeBg1) {
                if(this.$elVisible("#accessory_bg1")) {
                    this.beforeBg1 = false;
                }
            }
        },
        checkBg2() {
            if(this.beforeBg2) {
                if(this.$elVisible("#accessory_bg2")) {
                    this.beforeBg2 = false;
                }
            }
        } // End
    }
}
</script>

<style lang="less" scoped>
.body .full-ad.bg1 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-accessories/cloud-sensation-head.jpg');
}
.body .full-ad-3.bg2 {
    background-image: url('https://file.soocas.com/sg9_resource/soocas-site/l2-accessories/cloud-sensation-brightening.jpg');
}
.body .full-ad.bg1.bg-loading,
.body .full-ad-3.bg2.bg-loading {
    background-color: #f6f6f6;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/common/bg-transparent.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button {
    margin-top: 1.64063vw;
}
.body .half-width-ad .ad-box .ad-b-desc {
    height: 8.82812vw;
}
.body .half-width-ad .ad-box .ad-b-img.img1 {
    top: 16.09375vw;
    left: 15.27344vw;
    width: 17.07031vw;
    height: 21.32813vw;
}
.body .half-width-ad .ad-box .ad-b-img.img2 {
    top: 22.69531vw;
    left: 10.97656vw;
    width: 25.58594vw;
    height: 14.25781vw;
}

/*
.border-1_0px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.03906vw;}

.border-1_5px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.058594vw;}
*/

.border-2_0px,
.body .more .more-ads .more-text-box .more-text .more-text-button,
.body .half-width-ad .ad-box .ad-b-desc .ad-b-button span.adbb {border-width: 0.078125vw;}

.body {
    .full-ad.bg1 {
        .ad-body {
            top: 16.32813vw;
            left: 21.36719vw;
        }
    }
    .full-ad.full-ad-3.bg2 {
        .ad-body {
            top: 16.36719vw;
            left: 21.36719vw;
        }
    }
}

</style>
