<template>
    <div class="footer">
        <div class="footer-container">
            <!-- list 1 -->
            <div class="footer-list-1">
                <div class="item-1-logo">
                    <img src="https://file.soocas.com/sg9_resource/soocas-site/footer/logo-h-white.svg" />
                </div>
                <div class="item-1-entries">
                    <span>
                        <a href="https://cn.soocas.com/">中文</a>/<a href="https://usa.soocas.com/">EN</a>/<a href="https://ru.soocas.com/">RU</a>
                    </span>
                </div>
            </div>
            <!-- list 2 -->
            <div class="footer-list-2">
                <div class="item-2-title">购买素士</div>
                <div class="item-2-list">
                    <ul>
                        <li><a href="https://soocare.tmall.com/" target="_blank">天猫旗舰店</a></li>
                        <li><a href="https://mall.jd.com/index-1000086237.html" target="_blank">京东旗舰店</a></li>
                        <li><a href="https://www.douyin.com/user/MS4wLjABAAAAbqRtKtltr7yRAPcyPESy21s878t18IxQwUBhSJDEz7hro2kL2Azw1nRsgBrSaBiz" target="_blank">抖音旗舰店</a></li>
                    </ul>
                </div>
            </div>
            <!-- list 3 -->
            <div class="footer-list-2 footer-list-3">
                <div class="item-2-title">服务与支持</div>
                <div class="item-2-list">
                    <ul>
                        <li><a href="#/postsale?goto=postsale">售后服务</a></li>
                        <li><a href="#/postsale?goto=security">防伪验证</a></li>
                        <li><a href="#/postsale?goto=dealer">官方授权店验证</a></li>
                        <li><a href="#/agreement">服务协议</a></li>
                    </ul>
                </div>
            </div>
            <!-- list 4 -->
            <!--
                https://app.mokahr.com/edit-recruitment?type=social&orgId=soocas&siteId=95226&hireMode=1
            -->
            <div class="footer-list-2 footer-list-4">
                <div class="item-2-title">关于素士</div>
                <div class="item-2-list">
                    <ul>
                        <li><a href="#/about">公司简介</a></li>
                        <li><a href="#/subscribe">关注我们</a></li>
                        <li><a href="https://zhaopin.soocas.com" target="_blank">加入我们</a></li>
                        <li><a href="#/contact">联系我们</a></li>
                    </ul>
                </div>
            </div>
            <!-- list 5 -->
            <div class="footer-list-2 footer-list-5">
                <div class="item-2-title">
                    <div class="contact-icon contact-icon-wechat clickable" @mouseover="showQrcode" @mouseout="hideQrcode"
                        ></div><div class="contact-icon contact-icon-weibo clickable" @click="openLink('https://weibo.com/u/5679123217')"
                        ></div><div class="contact-icon contact-icon-red clickable" @click="openLink('https://www.xiaohongshu.com/user/profile/5b90fc8a5c191d00015fbdc2')"></div>
                    <div class="wechat-qrcode hidden"></div>
                </div>
                <div class="item-2-list">
                    <ul>
                        <li><span>客服电话</span></li>
                        <li><span>400-788-4008</span></li>
                        <li><span>周一至周五9:00AM-18:00PM</span></li>
                        <li><span>商务合作</span></li>
                        <li><span>sales@soocas.com</span></li>
                        <li><span>投资者关系</span></li>
                        <li><span>ir@soocas.com</span></li>
                    </ul>
                </div>
            </div>
            <!-- lines -->
            <div class="footer-line footer-line-1"></div>
            <div class="footer-line footer-line-2"></div>
            <div class="footer-line footer-line-3"></div>
            <div class="footer-line footer-line-4"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "AppFooter",
  data: function () {
    return {}
  },
  methods: {
    openLink(url, name) {
        window.open(url, name);
    },
    showQrcode() {
        document.querySelector(".wechat-qrcode").className = "wechat-qrcode"
    },
    hideQrcode() {
        document.querySelector(".wechat-qrcode").className = "wechat-qrcode hidden";
    }
  }
}
</script>

<style lang="less" scoped>
</style>