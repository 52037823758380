<template>
<!-- postsale box -->
<div class="body">
    <div class="full-ad ps-full-ad ps-ad-1"></div>
    <div class="full-ad ps-half-ad ps-ad-2">
        <div class="ps-ad2-title">售后服务</div>
        <div class="ps-ad2-content">
            <div class="ps-ad2-line ps-ad2-line1"></div>
            <div class="ps-ad2-line ps-ad2-line2"></div>

            <!-- policy description -->
            <div class="ps-ad2-block1">
                <div class="ad2-title ad2-b1-title">保修政策</div>
                <div class="ad2-b1-short">一年保修期，只换不修：</div>
                <div class="ad2-b1-detail">
                    <div><ol>
                        <li>自购买签收日起，一年内质量问题免费换新。</li>
                        <li>保修期内产品，换新需出示购买凭证或购买记录。</li>
                        <li>保修期内产品需寄回产品主机，即可进行换新。</li>
                        <li>换新范围：仅限在素士官方旗舰店购买的主品主机，牙刷头、冲牙器喷嘴等易损耗配件<br>不在保修范围内。</li>
                        <li>属于以下情况之一，不实行无偿保修：
                            <div><ul>
                                <li>使用过程中自行拆机，造成牙刷不能正常使用。</li>
                                <li>无法提供购买凭证或购买记录。</li>
                            </ul></div>
                        </li>
                    </ol></div>
                </div>
            </div>

            <!-- product security checkment -->
            <div class="ps-ad2-block2">
                <div class="ad2-title ad2-b2-title">防伪验证</div>
                <div class="ad2-b2-detail">
                    <div class="ad2-b2-desc">包装盒背面附防伪标签，刮开涂层，填防伪码查真伪</div>
                    <div class="ad2-b2-search">
                        <div class="ab2-search-icon"></div>
                        <!-- <div class="ab2-search-placeholder">请输入刮涂层后的验证码</div> -->
                        <div class="ab2-search-input">
                            <input type="text" id="soocasValidateCode" value=""  placeholder="请输入刮涂层后的验证码" />
                        </div>
                        <div class="ab2-search-button clickable" @click="securityQuery">防伪查询</div>
                    </div>
                    <div class="ad2-b2-preview-1"></div>
                    <div class="ad2-b2-preview-2"></div>
                    <div class="ad2-b2-state-1">刮开前</div>
                    <div class="ad2-b2-state-2">刮开后</div>
                </div>
            </div>

            <!-- dealer qualification verification -->
            <div class="ps-ad2-block3">
                <div class="ad2-title ad2-b2-title">官方授权<br>商家验证</div>
                <div class="ad2-b3-search">
                    <div class="ab2-search-icon"></div>
                    <!-- <div class="ab2-search-placeholder">请输入授权分销商网店名称或分销商网店完整链接地址</div> -->
                    <div class="ab2-search-input">
                        <input type="text" id="soocasDealerWords" value="" placeholder="请输入授权分销商网店名称或分销商网店完整链接地址" />
                    </div>
                    <div class="ab2-search-button clickable" @click="validateDealer">验证</div>
                </div>
                <div class="ad2-b3-detail">
                    <span>验证说明：</span>
                    <div><ol>
                        <li>使用公司全称或简称可查询是否为官方授权经销商或授权合作渠道；</li>
                        <li>使用网店名称或网店网址都可以验证是否为官方授权店铺；</li>
                        <li>填写需要验证的网址必须完整，格式为<span>https://</span>或<span>https://www.</span><br>例如：https://soocare.tmall.com；</li>
                        <li>未查询到或其他问题，请联系邮箱：sales@soocas.com</li>
                    </ol></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
let me = {
    _vue: null
};
if(window) {
    window.processWithIpAddress = function(ipJson) {
        if(me._vue) {
            me._vue.processVerify(ipJson);
        }
    };
    window.afterVerify355 = function(verifyResultJson) {
        me._vue.$modal.info({
            title: "查询结果：",
            content: verifyResultJson.FwResult
        });
    };
    window.afterVerify315 = function(verifyResultJson){
        switch(verifyResultJson.CodeState) {
        case 0:
        case "0":
            me._vue.$modal.info({
                title: "查询结果：",
                content: "您的设备并非素士设计和生产！如需帮助，请搜索【素士】官方微信公众号咨询"
            });
            break;
        case 1:
        case "1":
            me._vue.$modal.info({
                title: "查询结果：",
                content: "防伪码为素士官方出品，您正在使用素士官方正品"
            });
            break;
        case 2:
        case "2":
            me._vue.$modal.info({
                title: "查询结果：",
                content: "已验证素士，无需反复验证"
            });
            break;
        default:
            me._vue.$modal.info({
                title: "查询结果：",
                content: verifyResultJson.QueryResult
            });
            break;
        }
    };
}

export default {
    name: 'Postsale',
    data: function () {
        return {
            paramName: "goto",
            unit: document.documentElement.clientWidth/100,
            vwPos1: 33.68365,
            vwPos2: 66.31634,
            vwPos3: 96.21650,
            hasSoocare: false,
            url_355_get_ip: "https://gip.t3315.com/NewWebSrvCheckAddr/userip.ashx?jsoncallback=processWithIpAddress",
            url_355_verify: [
                "https://wsu.t3315.com/NewWebSrvCheckAddr/FWResult.aspx?jsoncallback=afterVerify355&QryType=APP&FwCode=",
                "&IpAddr=",
                "&MD5=HomFTGrK8Kf03Xjtj/JXJw=="
            ],
            url_315_verify: "https://www.zgfw-315.com/fwqueryjson.ashx?callback=afterVerify315&fwcode="
        }
    },
    mounted() {
        me._vue = this;
        let t = this.getParam(this.paramName);
        if(!t) {
            t = 4;
        }
        let pos1 = 0, pos2 = 0, pos3 = 0;
        switch(t) {
            case "postsale":
                pos1 = this.vwPos1 * this.unit;
                // console.log(pos1);
                window.scrollTo(0, pos1);
                break;
            case "security":
                pos2 = this.vwPos2 * this.unit;
                // console.log(pos2);
                window.scrollTo(0, pos2);
                break;
            case "dealer":
                pos3 = this.vwPos3 * this.unit;
                // console.log(pos3);
                window.scrollTo(0, pos3);
                break;
            default:
                window.scrollTo(0, 0);
                break;
        }
    },
    methods: {
        getParam(name) {
            // const search = location.search.substr(1);
            let search = location.hash.split("/postsale")[1];
            if(search.length > 0) search = search.substr(1);
            const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
            let res = search.match(reg);
            if(res === null) return null;
            return res[2];
        },
        requestJsonp(opt) {
            let head = document.querySelector('head');
            let script = document.createElement('script');
            let removeScript = function() {
                head.removeChild(script);
            };
            script.onload = function() {
                removeScript();
                if(opt.okHandler)
                    opt.okHandler.call();
            };
            script.onerror = function() {
                removeScript();
                if(opt.errHandler)
                    opt.errHandler.call();
                else {
                    this.$modal.error({
                        title: "错误提示",
                        content: "请求防伪码接口异常，请联系系统管理员"
                    });
                    if(console) {
                        console.error("错误请求：" + opt.sourceUrl);
                    }
                }
            };
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', opt.sourceUrl);
            head.appendChild(script);
        },
        processVerify(ipAddressJson) {
            let target = document.querySelector("#soocasValidateCode");
            let theCode = target.value;
            this.requestJsonp({
                sourceUrl:this.url_355_verify[0] + theCode + this.url_355_verify[1] + ipAddressJson.ipaddr + this.url_355_verify[2],
                okHandler: null,
                errHandler: null
            });
        },
        securityQuery() {
            let target = document.querySelector("#soocasValidateCode");
            let theCode = target.value;
            let regExNumber = /^[0-9]*[1-9][0-9]*$/;
            if(theCode.length == 0) {
                this.$modal.error({
                    title: "错误提示",
                    content: "防伪码不能为空"
                });
                target.focus();
                return false;
            } else if(theCode.length < 16) {
                this.$modal.error({
                    title: "错误提示",
                    content: "防伪码长度不能少于16位"
                });
                target.focus();
                return false;
            } else if(!regExNumber.test(theCode)) {
                this.$modal.error({
                    title: "错误提示",
                    content: "您输入的防伪码不是数字"
                });
                target.focus();
                return false;
            }
            if(this.hasSoocare) {
                if(theCode.length == 18) {
                    // old version
                    this.requestJsonp({
                        sourceUrl:this.url_315_verify + theCode,
                        okHandler: null,
                        errHandler: null
                    });
                } else {
                    // new version
                    this.requestJsonp({
                        sourceUrl:this.url_355_get_ip,
                        okHandler: null,
                        errHandler: null
                    });
                }
            } else {
                this.requestJsonp({
                    sourceUrl:this.url_355_get_ip,
                    okHandler: null,
                    errHandler: null
                });
            }
        },
        validateDealer() {
            let _this = this;
            let dwValue = document.querySelector("#soocasDealerWords").value;
            if(!dwValue || "" == dwValue) {
                this.$modal.error({
                    title: "错误提示",
                    content: "请输入分销商名称或链接地址"
                });
                return false;
            }
            let someStr = "";
            someStr += new Date().getTime();
            someStr += Math.round(Math.random() * 1000);
            let formData = new FormData();
            formData.append('s', someStr);
            formData.append('k', dwValue);
            _this.$axios.post(
                "https://m1.soocare.com/CustomerService/partner/a/k",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => {
                if(response.data) {
                    let json = response.data;
                    switch(json.data.result) {
                    case 2:
                        _this.$modal.error({
                            title: "错误提示",
                            content: json.data.extText + "！"
                        });
                        break;
                    case 1:
                        _this.$modal.success({
                            title: "操作成功",
                            content: "该商家已获得官方授权！"
                        });
                        break;
                    default:
                        _this.$modal.error({
                            title: "错误提示",
                            content: "该商家暂未获得官方授权！"
                        });
                        break;
                    }
                }
            }).catch((err) => {
                _this.$modal.error({
                    title: "错误提示",
                    content: "操作出错，请稍后重试或联系系统管理员。"
                });
                if(console)
                    console.error(err);
            });
            if(console)
                console.log(dwValue);
        }
    }
}
</script>

<style lang="less" scoped>
.full-ad.ps-full-ad {
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}
.full-ad.ps-half-ad {
}
.full-ad.ps-ad-1 {
    height:41.11328vw;
    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/postsale/oral-back-composed_nobg_orange-logo.jpg");
}
.full-ad.ps-ad-2 {
    height: 87.28516vw;

    .ps-ad2-title {
        position: relative;
        left: 0vw;
        top: 4.31641vw;
        width: 100%;
        height: 4.10156vw;
        text-align: center;
        font-family: FZLanTingHei-M-GBK;
        font-size: 3.51563vw;
        font-weight: 400;
        line-height: 4.10156vw;
        letter-spacing: 0em;
        color: #000000;
    }

    .ps-ad2-content {
        position: absolute;
        width: 44.45313vw;
        height: 65.97656vw;
        top: 13.30078vw;
        left: 27.773435vw;

        .ps-ad2-line {
            position: absolute;
            width: 44.45313vw;
            height: 0.03906vw;
            left: 0vw;
            background-color: rgba(0,0,0,0.4);
        }
        .ps-ad2-line1{
            top: 17.30468vw;
        }
        .ps-ad2-line2{
            top: 48.82813vw;
        }

        .ad2-title {
            position: absolute;
            left: 0vw;
            top: 0vw;
            font-family: FZLanTingHei-M-GBK;
            font-size: 1.875vw;
            font-weight: 400;
            line-height: 2.1875vw;
            letter-spacing: 0em;
            color: #000000;
        }

        .ps-ad2-block1 {
            position: absolute;
            width: 44.375vw;
            height: 11.5625vw;
            top: 0vw;
            left: 0vw;

            .ad2-title {
                width: 7.5vw;
                height: 2.1875vw;
            }

            .ad2-b1-short {
                position: absolute;
                width: 14.10156vw;
                height: 1.44531vw;
                top: 0.19531vw;
                left: 13.125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 1.25vw;
                font-weight: 400;
                line-height: 1.44531vw;
                letter-spacing: 0em;
                color: #000000;
            }

            .ad2-b1-detail {
                position: absolute;
                width: 31.25vw;
                height: 9.375vw;
                top: 2.1875vw;
                left: 13.125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.78125vw;
                font-weight: 400;
                line-height: 1.171875vw;
                letter-spacing: 0em;
                color: rgba(0,0,0,0.6);

                ol {
                    margin: 0;
                    padding: 0;
                    padding-left: 1em;

                    ul {
                        list-style-type: disc;
                        margin: 0;
                        padding: 0;
                        padding-left: 0.45em;
                    }
                }
            }
        }

        .ps-ad2-block2 {
            position: absolute;
            width: 44.375vw;
            height: 21.95313vw;
            top: 22.10938vw;
            left: 0vw;

            .ad2-title {
                width: 7.5vw;
                height: 2.1875vw;
            }

            .ad2-b2-detail {
                position: absolute;
                width: 31.25vw;
                height: 21.75781vw;
                top: 0.19531vw;
                left: 13.125vw;

                .ad2-b2-desc {
                    position: absolute;
                    width: 29.10156vw;
                    height: 1.44531vw;
                    top: 0.19531vw;
                    left: 0vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 1.44531vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad2-b2-search {
                    position: absolute;
                    width: 31.25vw;
                    height: 2.1875vw;
                    top: 4.25781vw;
                    left: 0vw;
                    border-radius: 1.09375vw;
                    border: 0.03906vw solid rgba(0,0,0,0.4);
                    overflow: hidden;
                }

                .ad2-b2-preview-1 {
                    position: absolute;
                    width: 13.59375vw;
                    height: 10.85938vw;
                    top: 8.59375vw;
                    left: 0vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/postsale/01.png");
                    background-size: 100% 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                .ad2-b2-preview-2 {
                    position: absolute;
                    width: 13.59375vw;
                    height: 10.85938vw;
                    top: 8.59375vw;
                    left: 17.65625vw;
                    background-image: url("https://file.soocas.com/sg9_resource/soocas-site/postsale/01-1.png");
                    background-size: 100% 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                .ad2-b2-state-1 {
                    position: absolute;
                    width: 3.75vw;
                    height: 1.44531vw;
                    top: 20.50781vw;
                    left: 4.92188vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 1.44531vw;
                    letter-spacing: 0em;
                    color: #000000;
                }

                .ad2-b2-state-2 {
                    position: absolute;
                    width: 3.75vw;
                    height: 1.44531vw;
                    top: 20.50781vw;
                    left: 22.57813vw;
                    font-family: FZLanTingHeiS-R-GB;
                    font-size: 1.25vw;
                    font-weight: 400;
                    line-height: 1.44531vw;
                    letter-spacing: 0em;
                    color: #000000;
                }
            }
        }

        .ps-ad2-block3 {
            position: absolute;
            width: 44.41406vw;
            height: 1.23438vw;
            top: 53.63281vw;
            left: 0vw;

            .ad2-title {
                width: 7.5vw;
                height: 4.375vw;
            }

            .ad2-b3-detail {
                position: absolute;
                width: 24.84375vw;
                height: 7.96875vw;
                top: 4.375vw;
                left: 13.125vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.78125vw;
                font-weight: 400;
                line-height: 1.328125vw;
                letter-spacing: 0em;
                color: #000000;

                span {
                    color: red;
                }

                ol {
                    margin: 0;
                    padding: 0;
                    padding-left: 1.1em;
                }
            }

            .ad2-b3-search {
                position: absolute;
                width: 31.25vw;
                height: 2.1875vw;
                top: 0.58594vw;
                left: 13.16406vw;
                border-radius: 1.09375vw;
                border: 1px solid rgba(0,0,0,0.4);
                overflow: hidden;
            }
        }

        .ad2-b2-search,.ad2-b3-search {
            .ab2-search-icon {
                position: absolute;
                width: 0.625vw;
                height: 0.625vw;
                top: 0.78125vw;
                left: 1.25vw;
                background-image: url("https://file.soocas.com/sg9_resource/soocas-site/postsale/search-icon.png");
                background-size: 100% 100%;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .ab2-search-placeholder {
                position: absolute;
                width: 23.04688vw;
                height: 1.79687vw;
                top: 0.19531vw;
                left: 2.38281vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.58594vw;
                font-weight: 400;
                line-height: 1.79688vw;
                letter-spacing: 0em;
                color: rgba(0,0,0,0.4);
            }

            .ab2-search-input {
                position: absolute;
                width: 23.04688vw;
                height: 1.79687vw;
                top: 0.19531vw;
                left: 2.38281vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.58594vw;
                font-weight: 400;
                line-height: 1.79688vw;
                letter-spacing: 0em;
                color: #000000;

                input {
                    border: none;
                    outline: none;
                    background: transparent;
                    width: 100%;
                }
                input:focus {
                    outline: none;
                }
                input::-moz-selection, input::selection {
                    background: transparent;
                }
            }

            .ab2-search-button {
                position: absolute;
                width: 5.82031vw;
                height: 2.1875vw;
                top: 0vw;
                left: 25.42969vw;
                font-family: FZLanTingHeiS-R-GB;
                font-size: 0.78125vw;
                font-weight: 400;
                line-height: 2.1875vw;
                letter-spacing: 0em;
                background: #000000;
                color: #FFFFFF;
                text-align:center;
                cursor: pointer;
            }
        }
    }
}
</style>