<template>
    <div class="copy-right">
        <div class="wrapper">
            <span>版权所有©2016</span>
            <span class="w-01">深圳素士科技股份有限公司&nbsp;</span>
            <img src="https://rs.soocare.com/soocas_official/images/picon.png">
            <span class="w-02 clickable" @click="gotoExternal">备案号：粤ICP备15084784号</span>
        </div>
    </div>
</template>

<script>
export default {
  name: "CopyRight",
  data: function () {
    return {}
  },
  methods: {
    gotoExternal() {
        window.open("http://beian.miit.gov.cn")
    }
  }
}
</script>

<style lang="less" scoped>
.copy-right {
    color: #333333;
    font-size: 0.78125vw;
    font-family: FZLanTingHei-L-GBK-M;
    line-height: 1.89145vw;

    .wrapper {
        position: relative;
        width: 40vw;
        height: 2.6vw;
        line-height: 2.6vw;
        margin: 0px auto;
        text-align: center;

        .w-01 {
            margin-left: 0.6vw;
            margin-right: 0.6vw;
        }

        img {
            width: auto;
            height: 1.12vw;
            display: inline-block;
            padding-bottom: 0.2vw;
        }
    }
}
</style>